export default function durations() {
  return (
    [
        '5min',
        '10min',
        '15min',
        '20min', 
        '25min',
        '30min',
        '35min',
        '40min',
        '45min',
        '50min',
        '55min',
        '1h',
        '1h 5min',
        '1h 10min',
        '1h 15min',
        '1h 20min', 
        '1h 25min',
        '1h 30min',
        '1h 35min',
        '1h 40min',
        '1h 45min',
        '1h 50min',
        '1h 55min',
        '2h',
        '2h 15min',
        '2h 30min',
        '2h 45min',
        '3h',
        '3h 15min',
        '3h 30min',
        '3h 45min',
        '4h',
        '4h 30min',
        '5h',
        '4h 30min',
        '6h',
        '4h 30min',
        '7h',
        '7h 30min',
        '8h',
        '9h',
        '10h',
        '11h',
        '12h'
    ]
  )
}

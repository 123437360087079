import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert, Container, Modal, Row, Col } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../config';
import { ref as dbRef, push, get, set, query, orderByChild, equalTo, child } from 'firebase/database';
import PlacesAutocomplete from 'react-places-autocomplete';
import Geocode from "react-geocode";
import { v4 } from 'uuid';
import { nanoid } from 'nanoid';

export default function Signup() {
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const contactNumberRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const businessNameRef = useRef();
    const websiteRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [showCustomerNameModal, setShowCustomerNameModal] = useState(false);
    const [showBusinessNameModal, setShowBusinessNameModal] = useState(false);
    const [showServicesOfferedModal, setShowServicesOfferedModal] = useState(false);
    const [showTeamSizeModal, setShowTeamSizeModal] = useState(false);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showHearAboutModal, setShowHearAboutModal] = useState(false);
    const [isPetGrooming, setIsPetGrooming] = useState(false);
    const [isPetBoarding, setIsPetBoarding] = useState(false);
    const [isPetSitting, setIsPetSitting] = useState(false);
    const [isDogWalking, setIsDogWalking] = useState(false);
    const [isDogTraining, setIsDogTraining] = useState(false);
    const [isOnePerson, setIsOnePerson] = useState(true);
    const [is2to5People, setIs2to5People] = useState(false);
    const [is6to10People, setIs6to10People] = useState(false);
    const [is11PlusPeople, setIs11PlusPeople] = useState(false);
    const [address, setAddress] = useState('');
    const [isRecommendedByFriend, setIsRecommendedByFriend] = useState(true);
    const [isSearchEngine, setIsSearchEngine] = useState(false);
    const [isSocialMedia, setIsSocialMedia] = useState(false);
    const [isRatingsSite, setIsRatingsSite] = useState(false);
    const [isOther, setIsOther] = useState(false);
    
    const navigate = useNavigate();

    const initialOpeningHours = {
        mon: { start: '10:00', end: '19:00', closed: false },
        tue: { start: '10:00', end: '19:00', closed: false },
        wed: { start: '10:00', end: '19:00', closed: false },
        thu: { start: '10:00', end: '19:00', closed: false },
        fri: { start: '10:00', end: '19:00', closed: false },
        sat: { start: '10:00', end: '16:00', closed: false },
        sun: { start: '10:00', end: '16:00', closed: true },
    }

    const colors = ['#00188f','#00bcf2','#00b294','#009e49','#bad80a','#fff100','#ff8c00','#e81123','#ec008c','#68217a'];

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value);
            push(dbRef(db, `${isBusiness ? 'Businesses' : 'Customers'}`), {
                email: emailRef.current.value,
                openingHours: initialOpeningHours
            });
            if (isBusiness) {
                setShowBusinessNameModal(true);
            } else {
                setShowCustomerNameModal(true);
            }
        } catch(err) {
            console.log(err);
            setError('Failed to create an account');
        }

        setLoading(false);
    }

    function submitBusinessName(e) {
        e.preventDefault();
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(emailRef.current.value));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Businesses/${dataKey}`);
            set(child(targetRef, 'businessName'), businessNameRef.current.value);
            set(child(targetRef, 'website'), websiteRef.current.value);
            set(child(targetRef, 'url'), businessNameRef.current.value.toLowerCase().replaceAll(' ', '-') + '-' + nanoid(11));
            set(child(targetRef, 'public'), true);
        });
        setShowBusinessNameModal(false);
        setShowServicesOfferedModal(true);
    }

    function addCategory(category, catalogue, i) {
        const serviceId1 = v4();
        const serviceId2 = v4();
        const categoryId = v4();
        catalogue['services'] = {
            ...catalogue['services'], 
            [serviceId1]: { id: serviceId1, name: 'Small dog', duration: 0.75, price: 10.00, onlineBookings: true, description: `${category.toLowerCase()} small dog` },
            [serviceId2]: { id: serviceId2, name: 'Large dog', duration: 1.5, price: 20.00, onlineBookings: true, description: `${category.toLowerCase()} large dog` },
        }
        catalogue['categories'] = {
            ...catalogue['categories'],
            [categoryId]: { id: categoryId, title: category, serviceIds: [serviceId1, serviceId2], color: colors[i%9] }
        }
        catalogue['categoryOrder'] = [ ...catalogue['categoryOrder'], categoryId ]
        return catalogue;
    }

    function submitServices(e) {
        e.preventDefault();
        
        let services = [];

        let catalogue = {
            services: {},
            categories: {},
            categoryOrder: []
        };
        let i = 0;
        if(isPetGrooming) {
            services.push('PetGrooming');
            catalogue = addCategory('Full Groom', catalogue, i);
            i++;
        }
        if(isPetBoarding) {
            services.push('PetBoarding');
            catalogue = addCategory('Full Day Board', catalogue, i);
            i++;
        }
        if(isPetSitting) {
            services.push('PetSitting');
            catalogue = addCategory('Full Day Pet Sit', catalogue, i);
            i++;
        }
        if(isDogWalking) {
            services.push('DogWalking');
            catalogue = addCategory('Dog Walk', catalogue, i);
            i++;
        }
        if(isDogTraining) {
            services.push('DogTraining');
            catalogue = addCategory('Dog Training Session', catalogue, i);
            i++;
        }
        
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(emailRef.current.value));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Businesses/${dataKey}`);
            set(child(targetRef, 'services'), services);
            set(child(targetRef, 'catalogue'), catalogue);
        });

        setShowServicesOfferedModal(false);
        setShowTeamSizeModal(true);
    }

    function submitTeamSize(e) {
        e.preventDefault();
        
        let teamSize = '';

        if(isOnePerson) teamSize = 'onePerson';
        if(is2to5People) teamSize = '2to5People';
        if(is6to10People) teamSize = '6to10People';
        if(is11PlusPeople) teamSize = '11PlusPeople';
        
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(emailRef.current.value));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Businesses/${dataKey}`);
            set(child(targetRef, 'teamSize'), teamSize);
        });

        setShowTeamSizeModal(false);
        setShowAddressModal(true);
    }

    function submitAddress(e) {
        e.preventDefault();
        
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(emailRef.current.value));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Businesses/${dataKey}`);
            set(child(targetRef, 'address'), address);

            Geocode.setApiKey("AIzaSyBEJLvZiEoMGGcnG6vGQkW-Av3yOR35BpQ");
            Geocode.fromAddress(address).then(response => {
                const { lat, lng } = response.results[0].geometry.location;
                set(child(targetRef, 'location'), {lat: lat, lng: lng});
            });
        });

        setShowAddressModal(false);
        setShowHearAboutModal(true);
    }

    function submitHearAbout(e) {
        e.preventDefault();
        
        let heardAbout = '';

        if(isRecommendedByFriend) heardAbout = 'RecommendedByFriend';
        if(isSearchEngine) heardAbout = 'SearchEngine';
        if(isSocialMedia) heardAbout = 'SocialMedia';
        if(isRatingsSite) heardAbout = 'RatingsSite';
        if(isOther) heardAbout = 'Other';
        
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(emailRef.current.value));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Businesses/${dataKey}`);
            set(child(targetRef, 'heardAbout'), heardAbout);
        });

        navigate('/dashboard');
    }

    function submitCustomer(e) {
        e.preventDefault();
        
        const dataRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(emailRef.current.value));
        get(dataRef).then((snapshot) => {
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Customers/${dataKey}`);
            set(child(targetRef, 'firstName'), firstNameRef.current.value);
            set(child(targetRef, 'lastName'), lastNameRef.current.value);
            set(child(targetRef, 'contactNumber'), contactNumberRef.current.value);
        }).then(() => {
            navigate('/dashboard');
        });
    }

    function handleChangeOnePerson() {
        if (!isOnePerson) {
            setIsOnePerson(true);
            setIs2to5People(false); 
            setIs6to10People(false); 
            setIs11PlusPeople(false);
        }
    }
    function handleChange2to5People() {
        if (!is2to5People) {
            setIsOnePerson(false);
            setIs2to5People(true); 
            setIs6to10People(false); 
            setIs11PlusPeople(false);
        }
    }
    function handleChange6to10People() {
        if (!is6to10People) {
            setIsOnePerson(false);
            setIs2to5People(false); 
            setIs6to10People(true); 
            setIs11PlusPeople(false);
        }
    }
    function handleChange11PlusPeople() {
        if (!is11PlusPeople) {
            setIsOnePerson(false);
            setIs2to5People(false); 
            setIs6to10People(false); 
            setIs11PlusPeople(true);
        }
    }
    
    function handleChangeRecommendedByFriend() {
        if (!isRecommendedByFriend) {
            setIsRecommendedByFriend(true);
            setIsSearchEngine(false); 
            setIsSocialMedia(false); 
            setIsRatingsSite(false);
            setIsOther(false);
        }
    }
    function handleChangeisSearchEngine() {
        if (!isSearchEngine) {
            setIsRecommendedByFriend(false);
            setIsSearchEngine(true); 
            setIsSocialMedia(false); 
            setIsRatingsSite(false);
            setIsOther(false);
        }
    }
    function handleChangeSocialMedia() {
        if (!isSocialMedia) {
            setIsRecommendedByFriend(false);
            setIsSearchEngine(false); 
            setIsSocialMedia(true); 
            setIsRatingsSite(false);
            setIsOther(false);
        }
    }
    function handleChangeRatingsSite() {
        if (!isRatingsSite) {
            setIsRecommendedByFriend(false);
            setIsSearchEngine(false); 
            setIsSocialMedia(false); 
            setIsRatingsSite(true);
            setIsOther(false);
        }
    }
    function handleChangeOther() {
        if (!isOther) {
            setIsRecommendedByFriend(false);
            setIsSearchEngine(false); 
            setIsSocialMedia(false); 
            setIsRatingsSite(false);
            setIsOther(true);
        }
    }
    
    return (
        <>
            <Modal show={showCustomerNameModal} fullscreen={true} onHide={() => {setShowCustomerNameModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Account setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
                        <div className='w-100' style={{ maxWidth: '400px' }}>
                            <h5>Please enter your name and contact number to finish setting up your account</h5>
                            <Card className='mt-3'>
                                <Card.Body>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={submitCustomer}>
                                        <Row>
                                            <Col>
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control type='text' ref={firstNameRef} required />
                                            </Col>
                                            <Col>
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control type='text' ref={lastNameRef} required />
                                            </Col>
                                        </Row>
                                        <Form.Label className='mt-3'>Contact number</Form.Label>
                                        <Form.Control type='tel' ref={contactNumberRef} required />
                                        <Button disabled={loading} className='w-100 mt-3' type='submit'>Create account</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showBusinessNameModal} fullscreen={true} onHide={() => {setShowBusinessNameModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Account setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
                        <div className='w-100' style={{ maxWidth: '400px' }}>
                            <h1>What's your business name?</h1>
                            <Card className='mt-3'>
                                <Card.Body>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={submitBusinessName}>
                                        <Form.Group id='business-name' className='mb-3'>
                                            <FloatingLabel label={'Business name'}>
                                                <Form.Control type='text' ref={businessNameRef} required />
                                            </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group id='website' className='mb-3'>
                                            <FloatingLabel label={'Website (Optional)'}>
                                                <Form.Control type='text' ref={websiteRef} />
                                            </FloatingLabel>
                                        </Form.Group>
                                        <Button disabled={loading} className='w-100 mt-2' type='submit'>Next step</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showServicesOfferedModal} fullscreen={true} onHide={() => {setShowServicesOfferedModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Account setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
                        <div className='w-100' style={{ maxWidth: '400px' }}>
                            <h1>What services do you offer?</h1>
                            <Card className='mt-3'>
                                <Card.Body>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={submitServices}>
                                        <ToggleButton className="mx-2 my-2" id="toggle-check1" type="checkbox" variant="outline-primary" checked={isPetGrooming} onChange={(e) => setIsPetGrooming(e.currentTarget.checked)}>Pet Grooming</ToggleButton>
                                        <ToggleButton className="mx-2 my-2" id="toggle-check2" type="checkbox" variant="outline-primary" checked={isPetBoarding} onChange={(e) => setIsPetBoarding(e.currentTarget.checked)}>Pet Boarding</ToggleButton>
                                        <ToggleButton className="mx-2 my-2" id="toggle-check3" type="checkbox" variant="outline-primary" checked={isPetSitting} onChange={(e) => setIsPetSitting(e.currentTarget.checked)}>Pet Sitting</ToggleButton>
                                        <ToggleButton className="mx-2 my-2" id="toggle-check4" type="checkbox" variant="outline-primary" checked={isDogWalking} onChange={(e) => setIsDogWalking(e.currentTarget.checked)}>Dog Walking</ToggleButton>
                                        <ToggleButton className="mx-2 my-2" id="toggle-check5" type="checkbox" variant="outline-primary" checked={isDogTraining} onChange={(e) => setIsDogTraining(e.currentTarget.checked)}>Dog Training</ToggleButton>
                                        <Button disabled={!(isPetGrooming||isPetBoarding||isPetSitting||isDogWalking||isDogTraining)} className='w-100 mt-2' type='submit'>Next step</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showTeamSizeModal} fullscreen={true} onHide={() => {setShowTeamSizeModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Account setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
                        <div className='w-100' style={{ maxWidth: '400px' }}>
                            <h1>What's your team size?</h1>
                            <Card className='mt-3'>
                                <Card.Body>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={submitTeamSize}>
                                        <Form.Check type="checkbox" label="It's just me" checked={isOnePerson} onChange={handleChangeOnePerson}/>
                                        <Form.Check type="checkbox" label="2-5 people" checked={is2to5People} onChange={handleChange2to5People}/>
                                        <Form.Check type="checkbox" label="6-10 people" checked={is6to10People} onChange={handleChange6to10People}/>
                                        <Form.Check type="checkbox" label="11+ people" checked={is11PlusPeople} onChange={handleChange11PlusPeople}/>
                                        <Button disabled={loading} className='w-100 mt-2' type='submit'>Next step</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showAddressModal} fullscreen={true} onHide={() => {setShowAddressModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Account setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
                        <div className='w-100' style={{ maxWidth: '400px' }}>
                            <h1>Set your location</h1>
                            <Card className='mt-3'>
                                <Card.Body>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={submitAddress}>
                                        <PlacesAutocomplete value={address} onChange={(e) => setAddress(e)} onSelect={(e) => setAddress(e)}>
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input {...getInputProps({ placeholder: 'Search Places ...', className: 'location-search-input', })}/>
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                                            const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div {...getSuggestionItemProps(suggestion, { className, style })} key={suggestion.description}>
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        <Button disabled={loading} className='w-100 mt-2' type='submit'>Next step</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal show={showHearAboutModal} fullscreen={true} onHide={() => {setShowHearAboutModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Account setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
                        <div className='w-100' style={{ maxWidth: '400px' }}>
                            <h1>How did you hear about us?</h1>
                            <Card className='mt-3'>
                                <Card.Body>
                                    {error && <Alert variant='danger'>{error}</Alert>}
                                    <Form onSubmit={submitHearAbout}>
                                        <Form.Check type="checkbox" label='Recommended by a friend' checked={isRecommendedByFriend} onChange={handleChangeRecommendedByFriend}/>
                                        <Form.Check type="checkbox" label='Search engine (e.g. Google, Bing)' checked={isSearchEngine} onChange={handleChangeisSearchEngine}/>
                                        <Form.Check type="checkbox" label='Social media' checked={isSocialMedia} onChange={handleChangeSocialMedia}/>
                                        <Form.Check type="checkbox" label='Ratings website (e.g. Trustpilot)' checked={isRatingsSite} onChange={handleChangeRatingsSite}/>
                                        <Form.Check type="checkbox" label='Other' checked={isOther} onChange={handleChangeOther}/>
                                        <Button variant='success' className='w-100 mt-2' type='submit'>Done</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
                <div className='w-100' style={{ maxWidth: '400px' }}>
                    <>
                        <Card>
                            <Card.Body>
                                <h2 className='text-center mb-4'>Sign Up</h2>
                                {error && <Alert variant='danger'>{error}</Alert>}
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group id='email' className='mb-3'>
                                        <FloatingLabel label={'Email'}>
                                            <Form.Control type='email' ref={emailRef} required />
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group id='password' className='mb-3'>
                                        <FloatingLabel label={'Password'}>
                                            <Form.Control type='password' ref={passwordRef} required />
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group id='password-confirm' className='mb-3'>
                                        <FloatingLabel label={'Confirm Password'}>
                                            <Form.Control type='password' ref={passwordConfirmRef} required />
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Check type="checkbox" label="Customer" checked={!isBusiness} onChange={() => setIsBusiness(!isBusiness)}/>
                                    <Form.Check type="checkbox" label="Business" checked={isBusiness} onChange={() => setIsBusiness(!isBusiness)}/>
                                    <Button disabled={loading} className='w-100 mt-2' type='submit'>Sign Up</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                        <div className='w-100 text-center mt-2'>
                            Already have an account? <Link to='/login'>Log In</Link>
                        </div>
                    </>
                </div>
            </Container>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../config';
import { ref as dbRef, get, query, orderByChild, equalTo } from 'firebase/database';
import BusinessDashboard from './BusinessDashboard';
import CustomerDashboard from './CustomerDashboard';
import Spinner from 'react-bootstrap/Spinner';

export default function Dashboard() {
  const [isBusiness, setIsBusiness] = useState('');
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    get(query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email))).then((snapshot) => {
      setIsBusiness(snapshot.val());
    }).then(() => {
      setLoading(false);
    })
  // eslint-disable-next-line
  }, [])

  return (
    <>
      {loading ? <Spinner animation="border" />: isBusiness ? <BusinessDashboard /> : <CustomerDashboard />}
    </>
  )
}

import { Col, Row, Button, Container } from "react-bootstrap";
import BusinessNavbar from './navbars/BusinessNavbar';

import '../custom.css';
import 'bootstrap/dist/css/bootstrap.css';

export default function BusinessHome() {

    return (
        <>
            <BusinessNavbar />
            <Container>
                <Row style={{padding:'100px'}}></Row>
                <Row className='main-row'>
                    <Col lg={2}></Col>
                    <Col lg={8} style={{textAlign:'center'}}>
                        <h1>The #1 Software for Dog Groomers</h1>
                        <p>Simple, flexible and powerful booking software for your business. And it's subscription-free!</p>
                        <Button>Join for free</Button>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
                <Row style={{padding:'100px'}}></Row>
            </Container>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { Button, Container, Table, OverlayTrigger, Popover, Alert, Accordion } from 'react-bootstrap';
import { db } from '../../config';
import { ref as dbRef, child, get, set, query, orderByChild, equalTo } from 'firebase/database';
import { useAuth } from '../../contexts/AuthContext';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

export default function HomeTab(props) {
  const data = props.appointments;
  const setData = props.setAppointments;

  const { currentUser } = useAuth();

  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState();
  const [appointmentsInProgress, setAppointmentsInProgress] = useState(false);

  useEffect(() => {
    // get data from firebase
    const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
    get(dataRef).then(snapshot => {
      setData(Object.values(snapshot.val())[0].appointments);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // update data in firebase
    if (data) {
      const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
      get(dataRef).then((snapshot) => {
        const dataKey = Object.keys(snapshot.val())[0];
        const targetRef = dbRef(db, `/Businesses/${dataKey}`);
        set(child(targetRef, 'appointments'), data);
      });

      Object.values(data).forEach(appointment => {
        if (moment(appointment.start).isBefore(moment()) && moment(appointment.end).isAfter(moment())) {
          setAppointmentsInProgress(true);
        }
      })
    }

    //eslint-disable-next-line
  }, [data]);

  function confirmAppointment(appointment) {
    const newAppointment = {...appointment, status: 'confirmed'};
    const newData = [...data];
    const index = data.map(e => e.id).indexOf(appointment.id);
    newData.splice(index, 1, newAppointment);
    setData(newData);
    const customerRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(appointment.client.email));
    get(customerRef).then((snapshot) => {
      const custAppointments = Object.values(snapshot.val())[0].appointments;
      const dataKey = Object.keys(snapshot.val())[0];
      const targetRef = dbRef(db, `/Customers/${dataKey}`);
      const i = custAppointments.map(e => e.id).indexOf(appointment.id);
      custAppointments.splice(i, 1, {...custAppointments[i], status: 'confirmed'});
      set(child(targetRef, 'appointments'), custAppointments);
    });
  }

  function cancelAppointment() {
    const index = data.map(e => e.id).indexOf(appointmentToCancel.id);
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    const customerRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(appointmentToCancel.client.email));
    get(customerRef).then((snapshot) => {
      const custAppointments = Object.values(snapshot.val())[0].appointments;
      const dataKey = Object.keys(snapshot.val())[0];
      const targetRef = dbRef(db, `/Customers/${dataKey}`);
      const i = custAppointments.map(e => e.id).indexOf(appointmentToCancel.id);
      custAppointments.splice(i, 1, {...custAppointments[i], status: 'cancelled'});
      set(child(targetRef, 'appointments'), custAppointments);
    });
    setShowCancelAlert(false);
    setAppointmentsInProgress(false);
  }

  const popover = (appointment) => { 
    return (
      <Popover id="popover-basic">
        <Popover.Body>
          {appointment.status==='pending' ? <><Button className='mb-1' size='sm' onClick={() => confirmAppointment(appointment)}>Confirm</Button><br/></> : null}
          <Button variant='danger' size='sm' onClick={() => {setAppointmentToCancel(appointment); setShowCancelAlert(true)}}>Cancel booking</Button>
        </Popover.Body>
      </Popover>
    );
  }
  

  const AppointmentsTable = (props) => {
    let appointments = [];
    data?.forEach(appointment => {
      if (((props.type==='upcoming' && moment(appointment.start).isAfter(moment()))
        || (props.type==='recent' && moment(appointment.start).isBefore(moment()))
          || (props.type==='in-progress' && moment(appointment.start).isBefore(moment()) && moment(appointment.end).isAfter(moment())))
            && !appointment.isBlock)
            appointments.push(appointment);
    })
    if (appointments.length < 1) return ( <p>None</p> )
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>id</th>
            <th>status</th>
            <th>client</th>
            <th>category</th>
            <th>service</th>
            <th>price</th>
            <th>start</th>
            <th>duration</th>
            {props.type==='upcoming' ? <th/> : null}
          </tr>
        </thead>
        <tbody>
          {appointments.map(appointment => {
            return (
              <tr key={`${props.type}-${appointment.id}`}>
                <td>{appointment.id}</td>
                <td>{appointment.status}</td>
                <td>{appointment.client.firstName} {appointment.client.lastName}<br/>{appointment.pets[0].name}</td>
                <td>{appointment.category.title}</td>
                <td>{appointment.service.name}</td>
                <td>{appointment.price}</td>
                <td>{moment(appointment.start).toDate().toLocaleString('en-GB', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                <td>{appointment.duration}</td>
                {props.type==='upcoming' || props.type==='in-progress' ? 
                  <td>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover(appointment)} rootClose>
                      <Button style={{
                          background: 'none',
                          color: 'inherit',
                          border: 'none',
                          padding: 0,
                          font: 'inherit',
                          outline: 'inherit'
                        }}>
                        <FontAwesomeIcon icon={solid("ellipsis-vertical")}/>
                      </Button>
                    </OverlayTrigger>
                  </td> 
                : null}
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <Container hidden={!props.show}>
        <h3 className='my-4'>Dashboard</h3>
        <Container className='my-5'>
          <h5>Upcoming</h5>
          <AppointmentsTable type='upcoming'/>
        </Container>
        
        <Container className='my-5' hidden={!appointmentsInProgress}>
          <hr/>
          <h5>In Progress</h5>
          <AppointmentsTable type='in-progress'/>
        </Container>

        <div className='my-5'>
          <hr/>
          <Accordion className='mt-4'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Previous Appointments</Accordion.Header>
              <Accordion.Body>
                <AppointmentsTable type='recent'/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        <Alert show={showCancelAlert} onClose={() => setShowCancelAlert(false)} dismissible variant='danger' className='my-2 mx-2'>
            Are you sure? this action cannot be undone. <Alert.Link className='w-10' variant='danger' onClick={() => cancelAppointment()}>Cancel</Alert.Link>
          </Alert>
      </Container>
    </>
  )
}

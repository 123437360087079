import { Col, Row, Button, Container, Badge, Card, Form, InputGroup } from "react-bootstrap";
import { db } from '../config';
import { ref as dbRef, get, query } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import CustomerNavbar from './navbars/CustomerNavbar';

import '../custom.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useRef, useState } from 'react';

export default function CustomerHome() {
    const [businesses, setBusinesses] = useState([]);
    const searchRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const dataRef = query(dbRef(db, `/Businesses`));
        get(dataRef).then((snapshot) => {
            setBusinesses(Object.values(snapshot.val()));
        });
    }, []);

    function submitSearch(e) {
        e.preventDefault();
        console.log(searchRef.current.value);
        searchRef.current.value = '';
    }

    return (
        <>
            <CustomerNavbar />
            <Container>
                <Row style={{padding:'100px'}}></Row>
                <Row className='main-row'>
                    <Col lg={2}></Col>
                    <Col lg={8} style={{textAlign:'center'}}>
                        <h1>Book local pet services</h1>
                        <Form onSubmit={submitSearch}>
                            <InputGroup className="my-4">
                                <Form.Control type='text' ref={searchRef} required />
                                <Button type='submit'>Search</Button>
                            </InputGroup>
                        </Form>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
                <Row style={{padding:'100px'}}></Row>
            </Container>
            <Container>
                {businesses.map(business => {
                    return (
                        business.public ? 
                        <Card key={`${business.email}-card`} style={{width: '18rem'}} className='business-card' onClick={() => navigate(`/${business.url}`)}>
                            <Card.Body>
                                <Card.Title>{business.businessName}</Card.Title>
                                <Card.Text>
                                    {business.address.split(',')[1]}, {business.address.split(',')[2]}
                                    {business.services.map(service => {
                                        return (
                                            <Badge bg='secondary' key={`${business.email}-${service}`} style={{marginRight: '5px'}}>{service.replace(/([A-Z])/g, " $1")}</Badge>
                                        )
                                    })}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        : null
                    )
                })}
            </Container>
        </>
    )
}

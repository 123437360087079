import { useAuth } from '../../contexts/AuthContext';
import { Navbar, Nav, Button, Container, OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export default function BusinessNavbar() {
    const navigate = useNavigate();

    const { currentUser, logout } = useAuth();

    async function handleLogout() {
        await logout();
        navigate('/for-business');
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <Button variant='light' onClick={handleLogout}>Logout</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <Navbar variant='dark' expand={'lg'} className="navbar-business" collapseOnSelect>
            <Container fluid>
                <Navbar.Brand href="#"><img src='/scruff-logo2.png' alt='' height='45' className='d-inline-block align-top mx-3'/></Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                <Navbar.Collapse className='my-3' id="offcanvasNavbar">
                    <Nav>
                        <Nav.Link eventKey="1" href='#features'>Features</Nav.Link>
                        <Nav.Link eventKey="2" href='#pricing'>Pricing</Nav.Link>
                        <Nav.Link eventKey="3" href='#support'>Support</Nav.Link>
                        <Nav.Link eventKey="4" href='mailto:hello@scruffbook.com'>Contact</Nav.Link>
                        <Button id='offcanvas-login-btn' variant='outline-light' href='/' className='mx-3'>For customers</Button>
                    </Nav>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        {currentUser ? 
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                            <Button id='offcanvas-login-btn' variant='outline-light'>{currentUser.email}</Button>
                        </OverlayTrigger> 
                        : 
                        <>
                            <Button id='offcanvas-signup-btn' className='mx-2' variant='light' href='/signup'>Sign up for free</Button>
                            <Button id='offcanvas-login-btn' variant='outline-light' href='/login'>Login</Button>
                        </>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Form, Button, Card } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { db, storage } from '../../config';
import { ref as dbRef, get, set, query, orderByChild, equalTo, child } from 'firebase/database';
import { ref as sRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export default function ProfileTab(props) {

  const { currentUser } = useAuth();

  const [description, setDescription] = useState();
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const [img3, setImg3] = useState();
  const [isPublic, setIsPublic] = useState();

  const descriptionRef = useRef();
  const imgRef1 = useRef();
  const imgRef2 = useRef();
  const imgRef3 = useRef();

  useEffect(() => {
    const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
    get(dataRef).then(snapshot => {
      setDescription(Object.values(snapshot.val())[0].description);
      setImg1(Object.values(snapshot.val())[0]['image-1']);
      setImg2(Object.values(snapshot.val())[0]['image-2']);
      setImg3(Object.values(snapshot.val())[0]['image-3']);
      setIsPublic(Object.values(snapshot.val())[0].public);
    });
    //eslint-disable-next-line
  }, []);

  function submitDescription(e) {
    e.preventDefault();
    
    const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
    get(dataRef).then((snapshot) => {
        const dataKey = Object.keys(snapshot.val())[0];
        const targetRef = dbRef(db, `/Businesses/${dataKey}`);
        set(child(targetRef, 'description'), descriptionRef.current.value);
    });

    setDescription(descriptionRef.current.value);
  }

  const uploadImg1 = e => {
    e.preventDefault();
    const uploadFile = imgRef1.current.files[0];
    const storageRef = sRef(storage, `${currentUser.email}/image-1.${uploadFile.name.split('.')[1]}`);
    const uploadTask = uploadBytesResumable(storageRef, uploadFile);
    uploadTask.then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
        get(dataRef).then((snapshot) => {
          const dataKey = Object.keys(snapshot.val())[0];
          const targetRef = dbRef(db, `/Businesses/${dataKey}`);
          set(child(targetRef, `image-1`), url);
          setImg1(url);
        });
      });
    })
  }

  const uploadImg2 = e => {
    e.preventDefault();
    const uploadFile = imgRef2.current.files[0];
    const storageRef = sRef(storage, `${currentUser.email}/image-2.${uploadFile.name.split('.')[1]}`);
    const uploadTask = uploadBytesResumable(storageRef, uploadFile);
    uploadTask.then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
        get(dataRef).then((snapshot) => {
          const dataKey = Object.keys(snapshot.val())[0];
          const targetRef = dbRef(db, `/Businesses/${dataKey}`);
          set(child(targetRef, `image-2`), url);
          setImg2(url);
        });
      });
    })
  }

  const uploadImg3 = e => {
    e.preventDefault();
    const uploadFile = imgRef3.current.files[0];
    const storageRef = sRef(storage, `${currentUser.email}/image-3.${uploadFile.name.split('.')[1]}`);
    const uploadTask = uploadBytesResumable(storageRef, uploadFile);
    uploadTask.then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
        get(dataRef).then((snapshot) => {
          const dataKey = Object.keys(snapshot.val())[0];
          const targetRef = dbRef(db, `/Businesses/${dataKey}`);
          set(child(targetRef, `image-3`), url);
          setImg3(url);
        });
      });
    })
  }

  useEffect(() => {
    if (isPublic !== undefined) {
      const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
      get(dataRef).then((snapshot) => {
          const dataKey = Object.keys(snapshot.val())[0];
          const targetRef = dbRef(db, `/Businesses/${dataKey}`);
          set(child(targetRef, 'public'), isPublic);
      });
    }
    //eslint-disable-next-line
  }, [isPublic])

  return (
    <>
      <Container hidden={!props.show}>
        <Row>
          <Col>
            <h3 className='my-4'>Profile Menu</h3>
          </Col>
          <Col>
            <Form.Check style={{float: 'right'}} className='my-4' type="switch" id="public-switch" label={isPublic ? 'Public' : 'Private'} checked={isPublic} onChange={() => setIsPublic(!isPublic)}/>
          </Col>
        </Row>
        <Row className='py-3'>
          <h5>Description</h5>
          <Form onSubmit={submitDescription}>
            <Form.Control as="textarea" className='mb-3' ref={descriptionRef} defaultValue={description} placeholder={description || 'Write description for your business here...'}/>
            <div style={{textAlign: 'right'}}>
              <Button className='w-10 mt-2' type='submit'>Save</Button>
            </div>
          </Form>
        </Row>
        <Row>
          <h5>Pictures</h5>
          <Col>
            <Card>
              <Card.Img variant="top" src={img1 || "default.png"} />
              <Card.Body>
                <Form onSubmit={uploadImg1}>
                  <Form.Control type="file" ref={imgRef1} className='mb-3'/>
                  <Button variant="primary" type='submit'>Upload</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img variant="top" src={img2 || "default.png"} />
              <Card.Body>
                <Form onSubmit={uploadImg2}>
                  <Form.Control type="file" ref={imgRef2} className='mb-3'/>
                  <Button variant="primary" type='submit'>Upload</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Img variant="top" src={img3 || "default.png"} />
              <Card.Body>
                <Form onSubmit={uploadImg3}>
                  <Form.Control type="file" ref={imgRef3} className='mb-3'/>
                  <Button variant="primary" type='submit'>Upload</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Col, Row, Button, Container, Badge, Carousel, Modal, Card, Form, Dropdown, DropdownButton } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import GoogleMapReact from 'google-map-react';
import breeds from './helpers/breeds';
import { useAuth } from '../contexts/AuthContext';
import CustomerNavbar from "./navbars/CustomerNavbar";
import { db } from '../config';
import { ref as dbRef, get, query, orderByChild, equalTo, set, child } from 'firebase/database';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { nanoid } from "nanoid";
import { useNavigate } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import '../search.css';

import "react-datepicker/dist/react-datepicker.css";

export default function BusinessPage(props) {
    const [serviceCategories, setServiceCategories] = useState();
    const [selectServicesModal, setSelectServicesModal] = useState();
    const [reviewBookingModal, setReviewBookingModal] = useState();
    const [bookingConfirmedModal, setBookingConfirmedModal] = useState();
    const [newPetModal, setNewPetModal] = useState();
    const [selectedServices, setSelectedServices] = useState([]);
    const [pets, setPets] = useState();
    const [newPetName, setNewPetName] = useState('');
    const [newPetAge, setNewPetAge] = useState(0);
    const [newPetBreed1, setNewPetBreed1] = useState('');
    const [newPetBreed2, setNewPetBreed2] = useState('');
    const [currentServiceId, setCurrentServiceId] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [availableTimeslots, setAvailableTimeslots] = useState();
    const [selectedTime, setSelectedTime] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [appointments, setAppointments] = useState([]);

    const commentsRef = useRef();

    const { currentUser } = useAuth();

    const navigate = useNavigate();

    const business = props.business;

    useEffect(() => {
        if (currentUser) {
            const dataRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(currentUser.email));
            get(dataRef).then(snapshot => {
                const userInfo = Object.values(snapshot.val())[0];
                setPets(userInfo.pets);
                setFirstName(userInfo.firstName);
                setLastName(userInfo.lastName);
                setContactNumber(userInfo.contactNumber);
            });
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (pets) {
          const dataRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(currentUser.email));
          get(dataRef).then((snapshot) => {
              const dataKey = Object.keys(snapshot.val())[0];
              const targetRef = dbRef(db, `/Customers/${dataKey}`);
              set(child(targetRef, 'pets'), pets);
          });
        }
        //eslint-disable-next-line
      }, [pets]);

    useEffect(() => {
        const newServiceCategories = Object.values(business.catalogue.categories);
        newServiceCategories[0].checked = true;
        setServiceCategories(newServiceCategories);
    }, [business]);

    function handleChecked(category) {
        const newCategory = {...category, checked: true};
        const newServiceCategories = [...serviceCategories];
        newServiceCategories.map(e => e.checked = false);
        const i = newServiceCategories.map(e => e.id).indexOf(category.id);
        newServiceCategories.splice(i, 1, newCategory);
        setServiceCategories(newServiceCategories);
    }

    function formatDuration(duration) {
        const hours = Math.floor(duration/1);
        const mins = (duration % 1)*60;
        return `${hours ? `${hours}h ` : ''}${mins ? `${mins}min` : ''}`;
    }

    function handleSelectService(e, serviceId) {
        if(e.target.checked) {
            if (selectedServices.length===0) {
                setSelectedServices([business.catalogue.services[serviceId]]);
            } else {
                setSelectedServices(prev => [...prev, business.catalogue.services[serviceId]]);
            }
        } else {
            const newSelectedServices = [...selectedServices];
            const i = newSelectedServices.map(e => e.id).indexOf(serviceId);
            newSelectedServices.splice(i, 1);
            setSelectedServices(newSelectedServices);
        }
    }

    function handleSelectPet(serviceId, pet) {
        const i = selectedServices.map(e => e.id).indexOf(serviceId);
        const newService = {...selectedServices[i], pet: pet};
        const newSelectedServices = [...selectedServices];
        newSelectedServices.splice(i, 1, newService);
        setSelectedServices(newSelectedServices);
    }

    function addNewPet(e) {
        e.preventDefault();
        const newPet = {
            name: newPetName,
            breed1: newPetBreed1,
            breed2: newPetBreed2,
            age: newPetAge
        }
        if (pets) {
            setPets(prev => [...prev, newPet]);
        } else {
            setPets([newPet]);
        }
        handleSelectPet(currentServiceId, newPet);
        setNewPetModal(false);
    }

    const getMaxTime = (date) => {
        // const longestDuration = selectedServices.length > 0 ? selectedServices.sort((a,b) => b.duration - a.duration)[0].duration : 0;
        const longestDuration = selectedServices.reduce((n, {duration}) => n + duration, 0);

        const dayNum = date.getDay();
        let day;
        if (dayNum===0) day = 'sun';
        if (dayNum===1) day = 'mon';
        if (dayNum===2) day = 'tue';
        if (dayNum===3) day = 'wed';
        if (dayNum===4) day = 'thu';
        if (dayNum===5) day = 'fri';
        if (dayNum===6) day = 'sat';
        return moment(date).hour(business.openingHours[day].end.split(':')[0]).minute(business.openingHours[day].end.split(':')[1]).subtract(longestDuration, 'hours').toDate();
    }

    const getMinTime = (date) => {
        const dayNum = date.getDay();
        let day;
        if (dayNum===0) day = 'sun';
        if (dayNum===1) day = 'mon';
        if (dayNum===2) day = 'tue';
        if (dayNum===3) day = 'wed';
        if (dayNum===4) day = 'thu';
        if (dayNum===5) day = 'fri';
        if (dayNum===6) day = 'sat';
        return moment(date).hour(business.openingHours[day].start.split(':')[0]).minute(business.openingHours[day].start.split(':')[1]).toDate()
    }

    const filterTimeslots = (date) => {
        // const longestDuration = selectedServices.length > 0 ? selectedServices.sort((a,b) => b.duration - a.duration)[0].duration : 0;
        const longestDuration = selectedServices.reduce((n, {duration}) => n + duration, 0);

        let filter = [];

        Array.from(business.appointments).forEach(a => {
            filter.push(!(moment(date).add(longestDuration, 'hours') > new Date(a.start) && moment(date) < new Date(a.end)))
        });

        return filter.every(Boolean);
    };

    useEffect(() => {
        if (selectedDate && selectedServices.length>0) {
            const minTime = getMinTime(selectedDate);
            const maxTime = getMaxTime(selectedDate);
            
            let newAvailableTimeslots = [];
            let timeslot = minTime;
            while(timeslot <= maxTime) {
                if (filterTimeslots(timeslot)) newAvailableTimeslots.push({time: timeslot, selected: false});
                timeslot = moment(timeslot).add(15, 'minutes').toDate();
            }
            setAvailableTimeslots(newAvailableTimeslots)
        } else {
            setAvailableTimeslots([]);
        }
        setSelectedTime(null);
        //eslint-disable-next-line
    }, [selectedDate, selectedServices]);

    const filterClosedDays = (date) => {
        const dayNum = date.getDay();
        let day;
        if (dayNum===0) day = 'sun';
        if (dayNum===1) day = 'mon';
        if (dayNum===2) day = 'tue';
        if (dayNum===3) day = 'wed';
        if (dayNum===4) day = 'thu';
        if (dayNum===5) day = 'fri';
        if (dayNum===6) day = 'sat';

        return !business.openingHours[day].closed;
    };

    function handleSelectTime(timeslot) {
        setSelectedTime(timeslot.time);
        const newTimeslot = {...timeslot, selected: true};
        const newAvailableTimeslots = [...availableTimeslots];
        newAvailableTimeslots.map(e => e.selected = false);
        const i = newAvailableTimeslots.map(e => e.time).indexOf(timeslot.time);
        newAvailableTimeslots.splice(i, 1, newTimeslot);
        setAvailableTimeslots(newAvailableTimeslots);
    }

    function getCategory(serviceId) {
        let serviceCategory;
        Object.values(business.catalogue.categories).forEach(category => {
            if (category.serviceIds.includes(serviceId)) {
                serviceCategory = category;
            }
        });
        return serviceCategory;
    }

    function buildAppointmentObject() {
        const client = { email: currentUser.email, created: moment().toDate().toLocaleString(), pets: pets, firstName: firstName, lastName: lastName, contactNumber: contactNumber }
        let currentDuration = 0;
        const newAppointments = [];
        selectedServices.forEach(service => {
            const category = getCategory(service.id);
            const appointment = {
                business: { name: business.businessName, address: business.address, email: business.email },
                category: category,
                client: client,
                desc: `${category.title} ${client.firstName} ${client.lastName} ${service.pet.name} ${service.pet.breed1} ${service.pet.breed2} ${service.pet.age} yrs`,
                duration: formatDuration(service.duration),
                end : moment(selectedTime).add(currentDuration, 'hours').add(service.duration, 'hours').toISOString(true).split('+')[0],
                id: nanoid(11),
                isBlock: false,
                notes: commentsRef.current.value,
                pets: [service.pet],
                price: service.price.toFixed(2),
                service: service,
                start: moment(selectedTime).add(currentDuration, 'hours').toISOString(true).split('+')[0],
                title: service.name,
                status: 'pending'
            }
            currentDuration = service.duration;
            newAppointments.push(appointment);
        });
        setAppointments(newAppointments);
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(business.email));
        get(dataRef).then((snapshot) => {
            const appointments = Object.values(snapshot.val())[0].appointments;
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Businesses/${dataKey}`);
            if (appointments) {
                set(child(targetRef, 'appointments'), appointments.concat(newAppointments));
            } else {
                set(child(targetRef, 'appointments'), newAppointments);
            }
        });
        const customerRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(currentUser.email));
        get(customerRef).then((snapshot) => {
            const appointments = Object.values(snapshot.val())[0].appointments;
            const dataKey = Object.keys(snapshot.val())[0];
            const targetRef = dbRef(db, `/Customers/${dataKey}`);
            if (appointments) {
                set(child(targetRef, 'appointments'), appointments.concat(newAppointments));
            } else {
                set(child(targetRef, 'appointments'), newAppointments);
            }
        });
        setBookingConfirmedModal(true);
    }

    const options = breeds('dog').map(function(breed) {
        return {name: breed, value: breed}
      });

    return (
        <>
            <Modal show={newPetModal} onHide={() => {setNewPetModal(false)}} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add new pet</Modal.Title>
                </Modal.Header>
                <Form onSubmit={addNewPet}>
                    <Modal.Body>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type='text' value={newPetName} onChange={(e) => setNewPetName(e.target.value)} placeholder='name' className="mb-3" required/>
                        <Form.Label>Breed 1</Form.Label>
                        <SelectSearch options={options} name="breeds" value={newPetBreed1} placeholder="Search breed 1" search onChange={setNewPetBreed1}/>
                        <Form.Label className="mt-3">Breed 2</Form.Label>
                        <SelectSearch options={options} name="breeds" value={newPetBreed2} placeholder="Search breed 2" search onChange={setNewPetBreed2}/>
                        <Form.Label className="mt-3">Age in years</Form.Label>
                        <Form.Control type='number' value={newPetAge} onChange={(e) => setNewPetAge(e.target.value)} placeholder='age' className="mb-3"/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='w-10 mt-3' type='submit' variant='success'>Save</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={bookingConfirmedModal} fullscreen={true} onHide={() => {setBookingConfirmedModal(false); window.location.reload()}}>
                <Modal.Header closeButton style={{backgroundColor: '#BA3B0A', color: 'white'}} closeVariant="white">
                    <Modal.Title>Booking confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: '#eeeeee'}} className="p-5">
                    <Card className="p-3">
                        <Card.Body>
                            <Card.Title className="mb-3">{business.businessName} have received your booking</Card.Title>
                            Your booking for:
                            <ul className="mt-3">
                                {appointments.map(appointment => {
                                    return (
                                        <li key={`appointment-${appointment.id}`}>{appointment.pets[0].name}'s {appointment.category.title} appointment on {moment(appointment.start).toDate().toLocaleString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</li>
                                    )
                                })}
                            </ul>
                            has been sent to {business.businessName}
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='w-10' variant='dark' disabled={false} onClick={() => {setBookingConfirmedModal(false); window.location.reload()}}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={reviewBookingModal} fullscreen={true} onHide={() => {setReviewBookingModal(false)}}>
                <Modal.Header closeButton style={{backgroundColor: '#BA3B0A', color: 'white'}} closeVariant="white">
                    <Modal.Title>Review and confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: '#eeeeee'}}>
                    <Row>
                        <Col className="p-4" xs={7}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Card details</Card.Title>
                                    <Card.Subtitle>Payment will be collected after your appointment</Card.Subtitle>
                                    <Form.Label className="mt-3">Card holder full name</Form.Label>
                                    <Form.Control></Form.Control>
                                    <Form.Label className="mt-3">Card number</Form.Label>
                                    <Form.Control className="mt-3"></Form.Control>
                                    <Row className="my-3">
                                        <Col>
                                            <Form.Label>Expiry date</Form.Label>
                                            <Form.Control></Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label>CVV</Form.Label>
                                            <Form.Control></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <h6>Cancellation policy</h6>
                                    Free cancellation up to 24 hours ahead, otherwise there will be a 30% charge for late cancellation or 50% for not showing up.
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-4" xs={5}>
                            <Card>
                                <Card.Body>
                                    <Card.Img variant="top" src={business['image-1']} style={{paddingLeft: '100px', paddingRight: '100px', paddingBottom: '20px'}}/>
                                    <Card.Title style={{textAlign: 'center'}}>{business.businessName}</Card.Title>
                                    {business.address}
                                    <hr/>
                                    <strong>
                                        {selectedTime?.toLocaleString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                        &nbsp;until {moment(selectedTime).add(selectedServices.reduce((n, {duration}) => n + duration, 0), 'hours').toDate().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' })}
                                        &nbsp;({formatDuration(selectedServices.reduce((n, {duration}) => n + duration, 0))})
                                    </strong>
                                    <hr/>
                                    {selectedServices.length===0 ? 'No services selected yet' : selectedServices.map(service => {
                                        return (
                                            <Row key={`cart-${service.id}`}>
                                                <Col>{service.name}</Col>
                                                <Col>£{service.price}</Col>
                                            </Row>
                                        )
                                    })}
                                    <hr/>
                                    <Row>
                                        <Col><strong>Total</strong></Col>
                                        <Col><strong>£{selectedServices.reduce((n, {price}) => n + price, 0).toFixed(2)}</strong></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="mt-3">
                                <Card.Body>
                                    <Card.Title>Comments</Card.Title>
                                    <Form.Control as="textarea" className='mb-3' ref={commentsRef} placeholder='Enter any comments here...'/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='w-10' variant='outline-secondary' onClick={() => {setSelectServicesModal(true); setReviewBookingModal(false)}}>Back</Button>
                    <Button className='w-10' variant='dark' disabled={false} onClick={() => {buildAppointmentObject(); setReviewBookingModal(false)}}>Confirm</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={selectServicesModal} fullscreen={true} onHide={() => {setSelectServicesModal(false)}}>
                <Modal.Header closeButton style={{backgroundColor: '#BA3B0A', color: 'white'}} closeVariant="white">
                    <Modal.Title>Book an appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{backgroundColor: '#eeeeee'}}>
                    <Row>
                        <Col className="p-4" xs={7}>
                            {Object.values(business.catalogue.categories).map(category => {
                                return (
                                    <Container className="mb-4" key={`category-container-${category.id}`}>
                                        <h4 className="mb-3">{category.title}</h4>
                                        {category.serviceIds.map(serviceId => {
                                            return (
                                                <Card className="p-2" key={`select-service-${serviceId}`}>
                                                    <Row>
                                                        <Col xs={2} style={{textAlign: 'center', position: 'relative'}}>
                                                            <Form.Check checked={selectedServices.filter(e => e.id === serviceId).length > 0} onChange={e => handleSelectService(e, serviceId)} style={{margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <strong>{business.catalogue.services[serviceId].name}</strong><br/>
                                                            <i>{formatDuration(business.catalogue.services[serviceId].duration)}</i><br/>
                                                            {business.catalogue.services[serviceId].description}
                                                            <DropdownButton hidden={!selectedServices.filter(e => e.id === serviceId).length > 0} title={selectedServices[selectedServices.map(e => e.id).indexOf(serviceId)]?.pet?.name || 'Select pet'}>
                                                                {pets?.map(pet => {
                                                                    return (
                                                                        <Dropdown.Item key={`select-service-${pet.name}`} onClick={() => handleSelectPet(serviceId, pet)}>{pet.name}</Dropdown.Item>
                                                                    )
                                                                })}
                                                                <Dropdown.Divider hidden={!pets || pets.length===0}/>
                                                                <Dropdown.Item as={Button} onClick={() => {setNewPetModal(true); setCurrentServiceId(serviceId)}}><FontAwesomeIcon icon={solid("plus")} /> Add new pet</Dropdown.Item>
                                                            </DropdownButton>
                                                        </Col>
                                                        <Col xs={2} style={{textAlign: 'center', position: 'relative'}}>
                                                            <p style={{margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>£{business.catalogue.services[serviceId].price.toFixed(2)}</p>
                                                        </Col>
                                                    </Row>
                                                </Card> 
                                            )
                                        })}
                                    </Container>
                                )
                            })}
                        </Col>
                        <Col className="p-4" xs={5}>
                            <Card>
                                <Card.Body>
                                    <Card.Img variant="top" src={business['image-1']} style={{paddingLeft: '100px', paddingRight: '100px', paddingBottom: '20px'}}/>
                                    <Card.Title style={{textAlign: 'center'}}>{business.businessName}</Card.Title>
                                    {business.address}
                                    <hr/>
                                    {selectedServices.length===0 ? 'No services selected yet' : selectedServices.map(service => {
                                        return (
                                            <Row key={`cart-${service.id}`}>
                                                <Col>{service.name}</Col>
                                                <Col>£{service.price}</Col>
                                            </Row>
                                        )
                                    })}
                                    <hr/>
                                    <Row>
                                        <Col><strong>Total</strong></Col>
                                        <Col><strong>£{selectedServices.reduce((n, {price}) => n + price, 0).toFixed(2)}</strong></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className="mt-3">
                                <Card.Body>
                                    <Card.Title>Select Time</Card.Title>
                                    <Row>
                                        <Col>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={e => setSelectedDate(e)}
                                                filterDate={filterClosedDays}
                                                minDate={moment().add(1, 'days').toDate()}
                                                className="mt-2"
                                                placeholderText="Select a timeslot"
                                                inline
                                            />
                                        </Col>
                                        <Col>
                                            {availableTimeslots?.map(timeslot => {
                                                return (
                                                    <Button key={`timeslot-${timeslot.time.getHours()}:${timeslot.time.getMinutes()<10?'0':''}${timeslot.time.getMinutes()}`} variant={timeslot.selected ? 'primary' : 'outline-primary'} className="mt-2" onClick={() => handleSelectTime(timeslot)}>
                                                        {timeslot.time.getHours()}:{timeslot.time.getMinutes()<10?'0':''}{timeslot.time.getMinutes()}
                                                    </Button>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='w-10' variant='dark' disabled={(selectedServices.length===0 || selectedServices.filter(e => e.pet == null).length > 0) || !selectedTime || selectedTime.getHours()===0} onClick={() => {setSelectServicesModal(false); setReviewBookingModal(true)}}>Continue</Button>
                </Modal.Footer>
            </Modal>

            <CustomerNavbar callback={business.url}/>
            <Row className='mt-5'>
                <Col xs={5}>
                    <Container style={{backgroundColor: '#eeeeee', height: '100%'}} className='mx-5 p-5'>
                        <h1>{business.businessName}</h1>
                        <h5>{business.address}</h5>
                        <Button className='mt-3' variant='dark' onClick={currentUser ? () => setSelectServicesModal(true) : () => {props.setCallback(business.url); navigate('/login')}}>Book now</Button>
                    </Container>
                </Col>
                <Col xs={7} className='px-5' style={{textAlign: 'center'}}>
                    <Carousel variant='dark'>
                        <Carousel.Item>
                            <img
                            style={{height: '37vh'}}
                            src={business['image-1'] || "default.png"}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            style={{height: '37vh'}}
                            src={business['image-2'] || "default.png"}
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            style={{height: '37vh'}}
                            src={business['image-3'] || "default.png"}
                            alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col xs={7} className='px-5 mb-5'>
                    <h3>About {business.businessName}</h3>
                    {business.services.map(service => {
                        return (
                            <Badge bg='secondary' key={`${business.email}-${service}`} style={{marginRight: '5px'}}>{service.replace(/([A-Z])/g, " $1")}</Badge>
                        )
                    })}
                    <div className='d-flex mt-3'>
                        {business.email ? <p style={{marginRight: '10px'}}><FontAwesomeIcon icon={solid("envelope")} /> {business.email}</p> : null}
                        {business.phoneNumber ? <p style={{marginRight: '10px'}}><FontAwesomeIcon icon={solid("phone")} /> {business.phoneNumber}</p> : null}
                        {business.website ? <p><FontAwesomeIcon icon={solid("link")} /> {business.website}</p> : null}
                    </div>
                    <p className='mt-3'>{business.description}</p>
                </Col>
                <Col xs={5} className='px-5 mb-5'>
                    <h3>Location</h3>
                    <p>{business.address}</p>
                    <div style={{ height: '20vh', width: '100%' }}>
                        <GoogleMapReact bootstrapURLKeys={{ key: "" }} defaultCenter={business.location} defaultZoom={10}>
                            <FontAwesomeIcon icon={solid("location-dot")} lat={business.location?.lat} lng={business.location?.lng} text={business.address} size='3x' style={{position: 'absolute', transform: 'translate(-50%, -100%)', color: '#BA3B0A'}}/>
                        </GoogleMapReact>
                    </div>
                    <hr/>
                    <h5>Opening hours</h5>
                    <Row style={{fontWeight: (new Date()).getDay()===1 ? 'bold' : 'normal'}}>
                        <Col>Monday</Col>
                        <Col>{business.openingHours.mon.closed ? 'closed' : `${business.openingHours.mon.start} - ${business.openingHours.mon.end}`}</Col>
                    </Row>
                    <Row style={{fontWeight: (new Date()).getDay()===2 ? 'bold' : 'normal'}}>
                        <Col>Tuesday</Col>
                        <Col>{business.openingHours.tue.closed ? 'closed' : `${business.openingHours.tue.start} - ${business.openingHours.tue.end}`}</Col>
                    </Row>
                    <Row style={{fontWeight: (new Date()).getDay()===3 ? 'bold' : 'normal'}}>
                        <Col>Wednesday</Col>
                        <Col>{business.openingHours.wed.closed ? 'closed' : `${business.openingHours.wed.start} - ${business.openingHours.wed.end}`}</Col>
                    </Row>
                    <Row style={{fontWeight: (new Date()).getDay()===4 ? 'bold' : 'normal'}}>
                        <Col>Thursday</Col>
                        <Col>{business.openingHours.thu.closed ? 'closed' : `${business.openingHours.thu.start} - ${business.openingHours.thu.end}`}</Col>
                    </Row>
                    <Row style={{fontWeight: (new Date()).getDay()===5 ? 'bold' : 'normal'}}>
                        <Col>Friday</Col>
                        <Col>{business.openingHours.fri.closed ? 'closed' : `${business.openingHours.fri.start} - ${business.openingHours.fri.end}`}</Col>
                    </Row>
                    <Row style={{fontWeight: (new Date()).getDay()===6 ? 'bold' : 'normal'}}>
                        <Col>Saturday</Col>
                        <Col>{business.openingHours.sat.closed ? 'closed' : `${business.openingHours.sat.start} - ${business.openingHours.sat.end}`}</Col>
                    </Row>
                    <Row style={{fontWeight: (new Date()).getDay()===0 ? 'bold' : 'normal'}}>
                        <Col>Sunday</Col>
                        <Col>{business.openingHours.sun.closed ? 'closed' : `${business.openingHours.sun.start} - ${business.openingHours.sun.end}`}</Col>
                    </Row>
                </Col>
                <hr/>
            </Row>
            <Row className='mt-4'>
                <Col xs={5} className='px-5 mb-4'>
                    <h3>Services</h3>
                </Col>
                <Col xs={7} className='px-5 mb-4' />
            </Row>
            <Row>
                <Col xs={4} className='px-5 mb-4'>
                {serviceCategories?.map((category, i) => {
                        return (
                            <div className='mb-2'>
                                <Button variant={category.checked ? 'primary' : 'secondary'} className='w-100' style={{textAlign: 'left'}} key={`toggle-${category.id}`} checked={category.checked} onClick={() => handleChecked(category)}>
                                    {category.title} &nbsp;&nbsp;{category.serviceIds.length}
                                </Button>
                            </div>
                        )
                    })}
                </Col>
                <Col xs={8} className='px-5 mb-4'>
                    {serviceCategories?.filter(e => e.checked).map(category => {
                        return (
                            <div key={`services-${category.id}`}>
                                {category.serviceIds.map(serviceId => {
                                    return (
                                        <Row className='mb-3' key={`service-${serviceId}`}>
                                            <Col xs={10} className='mb-3'>
                                                <strong>{business.catalogue.services[serviceId].name}</strong><br/>
                                                <i>{formatDuration(business.catalogue.services[serviceId].duration)}</i><br/>
                                                {business.catalogue.services[serviceId].description}
                                            </Col>
                                            <Col xs={2} className='mb-3'>
                                                £{business.catalogue.services[serviceId].price}
                                            </Col>
                                            <hr/>
                                        </Row>
                                    )
                                })}
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </>
    )
}

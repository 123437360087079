export default function breeds(animal) {
    let breedList = [];

    if (animal==='dog') {
        breedList = [
            "",
            "Abruzzenhund",
            "Affenpinscher",
            "Afghan Hound",
            "Africanis",
            "Aidi",
            "Ainu Dog",
            "Airedale Terrier",
            "Akbash Dog",
            "Akitas",
            "Akita (American)",
            "Akita Inu (Japanese)",
            "Alano Español",
            "Alapaha Blue Blood Bulldog",
            "Alaskan Husky",
            "Alaskan Klee Kai",
            "Alaskan Malamute",
            "Alopekis",
            "Alpine Dachsbracke",
            "American Allaunt",
            "American Alsatian",
            "American Black and Tan Coonhound",
            "American Blue Gascon Hound",
            "American Blue Lacy",
            "American Bull Molosser",
            "American Bulldog",
            "American Bullnese",
            "American Bully",
            "American Cocker Spaniel",
            "American Crested Sand Terrier",
            "American English Coonhound",
            "American Eskimo Dog",
            "American Foxhound",
            "American Hairless Terrier",
            "American Indian Dog",
            "American Lo-Sze Pugg",
            "American Mastiff",
            "American Mastiff (Panja)",
            "American Pit Bull Terrier",
            "American Staffordshire Terrier",
            "American Staghound",
            "American Toy Terrier",
            "American Tundra Shepherd Dog",
            "American Water Spaniel",
            "American White Shepherd",
            "Anangu",
            "Anatolian Shepherd Dog",
            "Andalusian Podenco",
            "Anglo-Françaises",
            "Anglo-Françai Grand",
            "Anglo-Françaises de Moyenne Venerie",
            "Anglo-Françaises de Petite Venerie",
            "Appenzell Mountain Dog",
            "Ariege Pointing Dog",
            "Ariegeois",
            "Armant",
            "Aryan Molossus",
            "Argentine Dogo",
            "Armenian Gampr",
            "Atlas Terrier",
            "Australian Bandog",
            "Australian Bulldog",
            "Australian Cattle Dog",
            "Australian Cobberdog",
            "Australian Kelpie",
            "Australian Koolie",
            "Australian Labradoodle",
            "Australian Shepherd",
            "Australian Stumpy Tail Cattle Dog",
            "Australian Terrier",
            "Austrian Black and Tan Hound",
            "Austrian Brandlbracke",
            "Austrian Shorthaired Pinscher",
            "Auvergne Pointing Dog",
            "Azawakh",
            "Balkan Hound",
            "Balkan Scenthound",
            "Balkanski Gonic",
            "Bando Bull",
            "Banjara Greyhound",
            "Banter Bulldogge",
            "Barbet",
            "Basenji",
            "Basset Artesien Normand",
            "Basset Bleu de Gascogne",
            "Basset Fauve de Bretagne",
            "Basset Hound",
            "Bavarian Mountain Scent Hound",
            "Beagle",
            "Beagle Harrier",
            "Bearded Collie",
            "Beauceron",
            "Bedlington Terrier",
            "Bedouin Shepherd Dog",
            "Belgian Griffons",
            "Belgian Mastiff",
            "Belgian Shepherd Groenendael",
            "Belgian Shepherd Laekenois",
            "Belgian Shepherd Malinois",
            "Belgian Shepherd Tervuren",
            "Belgian Shorthaired Pointer",
            "Belgrade Terrier",
            "Bench-legged Feist",
            "Bergamasco",
            "Berger Blanc Suisse",
            "Berger des Picard",
            "Berger des Pyrénées",
            "Berger Du Languedoc",
            "Bernese Hound",
            "Bernese Mountain Dog",
            "Bhagyari Kutta",
            "Bichon Frise",
            "Bichon Havanais",
            "Biewer",
            "Billy",
            "Biro",
            "Black and Tan Coonhound",
            "Black Forest Hound",
            "Black Mouth Cur",
            "Black Norwegian Elkhound",
            "Black Russian Terrier",
            "Bleus de Gascogne",
            "Bloodhound",
            "Blue Gascony Basset (picture needed)",
            "Blue Heeler",
            "Blue Lacy",
            "Blue Picardy Spaniel",
            "Bluetick Coonhound",
            "Boerboel",
            "Bohemian Shepherd",
            "Bohemian Terrier",
            "Bolognese",
            "Bonsai Bulldogge",
            "Border Collie",
            "Border Terrier",
            "Borzoi",
            "Bosanski Ostrodlaki Gonic Barak",
            "Bosnian-Herzegovinian Sheepdog - Tornjak",
            "Boston Terrier",
            "Bouvier de Ardennes",
            "Bouvier des Flandres",
            "Boxer",
            "Boykin Spaniel",
            "Bracco Italiano",
            "Braque du Bourbonnais",
            "Braque Dupuy",
            "Braque Francais (picture needed)",
            "Brazilian Terrier",
            "Briard",
            "Brittany Spaniel",
            "Briquet",
            "Briquet Griffon Vendeen",
            "Broholmer",
            "Brussels Griffon",
            "Bukovina Sheepdog",
            "Buldogue Campeiro",
            "Bull Terrier",
            "Bully Kutta",
            "Bulldog",
            "Bullmastiff",
            "Cairn Terrier",
            "Cajun Squirrel Dog",
            "Cambodian Razorback Dog",
            "Canaan Dog",
            "Canadian Eskimo Dog",
            "Canadian Inuit Dog",
            "Canary Dog",
            "Canarian Warren Hound",
            "Cane Corso Italiano",
            "Canis Panther",
            "Canoe Dog",
            "Cão da Serra da Estrela",
            "Cão da Serra de Aires",
            "Cão de Castro Laboreiro",
            "Cão de Fila de São Miguel",
            "Cão de Gado Transmontano",
            "Caravan Hound",
            "Carlin Pinscher",
            "Carolina Dog",
            "Carpathian Sheepdog",
            "Catahoula Leopard Dog",
            "Catalan Sheepdog",
            "Cardigan Welsh Corgi",
            "Caucasian Ovtcharka",
            "Cavalier King Charles Spaniel",
            "Central Asian Ovtcharka",
            "Cesky Fousek",
            "Cesky Terrier",
            "Chart Polski",
            "Chesapeake Bay Retriever",
            "Chien D'Artois",
            "Chien De L' Atlas",
            "Chien Française",
            "Chihuahua",
            "Chin",
            "Chinese Chongqing Dog",
            "Chinese Crested",
            "Chinese Foo Dog",
            "Chinese Imperial Dog",
            "Chinese Shar-Pei",
            "Chinook",
            "Chippiparai",
            "Chiribaya Shepherd",
            "Chortaj",
            "Chow Chow",
            "Chuandong Hound",
            "Cierny Sery",
            "Cimarron Uruguayo",
            "Cirneco Dell'Etna",
            "Clumber Spaniel",
            "Cocker Spaniel",
            "Collie (Rough and Smooth)",
            "Combai",
            "Continental Bulldog",
            "Continental Toy Spaniel",
            "Coochi",
            "Corgi",
            "Coton de Tulear",
            "Cretan Hound",
            "Croatian Sheepdog",
            "Curly-Coated Retriever",
            "Cypro Kukur",
            "Czechoslovakian Wolfdog",
            "Czesky Terrier",
            "Dachshund",
            "Dakotah Shepherd",
            "Dalmatian",
            "Dandie Dinmont Terrier",
            "Danish Broholmer",
            "Danish-Swedish Farmdog",
            "Denmark Feist",
            "Deutsche Bracke",
            "Deutsch Drahthaar",
            "Deutscher Wachtelhund",
            "Dingo",
            "Doberman Pinscher",
            "Dogo Argentino",
            "Dogue de Bordeaux",
            "Dorset Olde Tyme Bulldogge",
            "Drentse Patrijshond",
            "Drever",
            "Dunker",
            "Dutch Shepherd Dog",
            "Dutch Smoushond",
            "East-European Shepherd",
            "East Russian Coursing Hound",
            "East Siberian Laika",
            "Elkhound (see Norwegian Elkhound)",
            "English Bulldog",
            "English Bullen Bordeaux Terrier",
            "English Cocker Spaniel",
            "English Coonhound",
            "English Foxhound",
            "English Pointer",
            "English Setter",
            "English Shepherd",
            "English Springer Spaniel",
            "English Toy Spaniel",
            "Entlebucher Sennenhund",
            "Estonian Hound",
            "Estrela Mountain Dog",
            "Eurasier",
            "Farm Collie",
            "Fauve de Bretagne",
            "Feist",
            "Field Spaniel",
            "Fila Brasileiro",
            "Finnish Hound",
            "Finnish Lapphund",
            "Finnish Spitz",
            "Flat-Coated Retriever",
            "Foxhound",
            "Fox Terrier",
            "French Brittany Spaniel",
            "French Bulldog",
            "French Mastiff",
            "French Pointing Dog",
            "French Spaniel",
            "French Tricolor Hound",
            "French White and Black Hound",
            "French White and Orange Hound",
            "Galgo Español",
            "Gammel Dansk Hoensehund",
            "Gascons-Saintongeois",
            "Georgian Shepherd (picture needed)",
            "Georgian Mountain Dog (picture needed)",
            "German Hunt Terrier",
            "German Longhaired Pointer",
            "German Rough-haired Pointing Dog",
            "German Pinscher",
            "German Sheeppoodle",
            "German Shepherd Dog",
            "German Shorthaired Pointer",
            "German Spitz",
            "German Spitz Giant",
            "German Spitz Medium",
            "German Spitz Small",
            "German Wirehaired Pointer",
            "Giant Maso Mastiff",
            "Giant Schnauzer",
            "Glen of Imaal Terrier",
            "Golddust Yorkshire Terrier",
            "Golden Retriever",
            "Gordon Setter",
            "Gran Mastin de Borinquen",
            "Grand Anglo-Français",
            "Grand Anglo-Français Tricolore",
            "Grand Anglo-Français Blanc et Noir",
            "Grand Anglo-Français Blanc et Orange",
            "Grand Basset Griffon Vendeen",
            "Grand Bleu de Gascogne",
            "Grand Gascon Saintongeois",
            "Grand Griffon Vendeen",
            "Great Dane",
            "Great Pyrenees",
            "Greater Swiss Mountain Dog",
            "Greek Hound",
            "Greek Sheepdog",
            "Greenland Dog",
            "Greyhound",
            "Griffon Bleu de Gascogne",
            "Griffon Fauve de Bretagne",
            "Griffon Nivernais",
            "Groenendael",
            "Grosser Münsterlander Vorstehhund",
            "Guatemalan Bull Terrier",
            "Hairless Khala",
            "Halden Hound",
            "Hamilton Hound",
            "Hanoverian Hound",
            "Harlequin Pinscher",
            "Harrier",
            "Havanese",
            "Hawaiian Poi Dog",
            "Hellenikos Ichnilatis",
            "Hellenikos Poimenikos (see Greek Sheepdog)",
            "Hertha Pointer",
            "Himalayan Sheepdog",
            "Hokkaido Dog",
            "Hanoverian Scenthound",
            "Hovawart",
            "Hungarian Greyhound (see Magyar Agar)",
            "Hungarian Kuvasz",
            "Hungarian Puli",
            "Hungarian Wire-haired Pointing Dog",
            "Husky",
            "Hygenhund",
            "Ibizan Hound",
            "Icelandic Sheepdog",
            "Inca Hairless Dog",
            "Indian Spitz",
            "Irish Glen Imaal Terrier",
            "Irish Red and White Setter",
            "Irish Setter",
            "Irish Staffordshire Bull Terrier",
            "Irish Terrier",
            "Irish Water Spaniel",
            "Irish Wolfhound",
            "Istrian Coarse-haired Hound",
            "Istrian Shorthaired Hound",
            "Italian Greyhound",
            "Italian Hound",
            "Italian Spinoni",
            "Jack Russell Terrier",
            "Jagdterrier",
            "Jamthund",
            "Japanese Spaniel (Chin)",
            "Japanese Spitz",
            "Japanese Terrier",
            "Jindo",
            "Kai Dog",
            "Kangal Dog",
            "Kangaroo Dog",
            "Kanni",
            "Karabash",
            "Karakachan",
            "Karelian Bear Dog",
            "Karelian Bear Laika",
            "Karelo-Finnish Laika",
            "Karst Shepherd",
            "Kattai Nai",
            "Keeshond",
            "Kelb Tal-Fenek",
            "Kemmer Feist",
            "Kerry Beagle",
            "Kerry Blue Terrier",
            "King Charles Spaniel",
            "King Shepherd",
            "Kishu Ken",
            "Klein Poodle",
            "Kokoni",
            "Komba",
            "Komondor",
            "Koochee",
            "Kooikerhondje",
            "Koolie",
            "Korean Dosa Mastiff",
            "Krasky Ovcar",
            "Kromfohrlander",
            "Kuchi",
            "Kugsha Dog",
            "Kukkal",
            "Kunming Dog",
            "Kuvasz",
            "Kyi-Leo®",
            "Labrador Husky",
            "Labrador Retriever",
            "Lagotto Romagnolo",
            "Laizhou Hong",
            "Lakeland Terrier",
            "Lakota Mastino",
            "Lancashire Heeler",
            "Landseer",
            "Lapinporokoira",
            "Lapphunds",
            "Lapponian Herder",
            "Large Münsterländer",
            "Larson Lakeview Bulldogge",
            "Latvian Hound",
            "Leavitt Bulldog",
            "Leonberger",
            "Levesque",
            "Lhasa Apso",
            "Lithuanian Hound",
            "Llewellin Setter",
            "Longhaired Whippet (picture needed)",
            "Louisiana Catahoula Leopard Dog",
            "Löwchen (Little Lion Dog)",
            "Lucas Terrier",
            "Lundehund",
            "Magyar Agar",
            "Mahratta Greyhound",
            "Majestic Tree Hound",
            "Majorca Shepherd Dog",
            "Maltese",
            "Mammut Bulldog",
            "Manchester Terrier",
            "Maremma Sheepdog",
            "Markiesje",
            "Mastiff",
            "McNab",
            "Mexican Hairless",
            "Mi-Ki",
            "Middle Asian Ovtcharka",
            "Miniature American Eskimo",
            "Miniature Australian Bulldog",
            "Miniature Australian Shepherd",
            "Miniature Bull Terrier",
            "Miniature Fox Terrier",
            "Miniature Pinscher",
            "Miniature Poodle",
            "Miniature Schnauzer",
            "Miniature Shar-Pei",
            "Mioritic Sheepdog",
            "Moscow Toy Terrier",
            "Moscow Vodolaz",
            "Moscow Watchdog",
            "Mountain Cur",
            "Mountain Feist",
            "Mountain View Cur",
            "Moyen Poodle",
            "Mucuchies",
            "Mudi",
            "Munsterlander",
            "Native American Indian Dog",
            "Neapolitan Mastiff",
            "Nebolish Mastiff",
            "Nenets Herding Laika",
            "New Guinea Singing Dog",
            "New Zealand Heading Dog",
            "New Zealand Huntaway",
            "Newfoundland",
            "Norrbottenspets",
            "Norfolk Terrier",
            "North American Miniature Australian Shepherd",
            "Northeasterly Hauling Laika",
            "Northern Inuit Dog",
            "Norwegian Buhund",
            "Norwegian Elkhound",
            "Norwegian Hound",
            "Norwegian Lundehund",
            "Norwich Terrier",
            "Nova Scotia Duck-Tolling Retriever",
            "Ol' Southern Catchdog",
            "Old Danish Chicken Dog",
            "Old English Mastiff",
            "Old English Sheepdog (Bobtail)",
            "Old-Time Farm Shepherd",
            "Olde Boston Bulldogge",
            "Olde English Bulldogge",
            "Olde Victorian Bulldogge",
            "Original English Bulldogge",
            "Original Mountain Cur",
            "Otterhound",
            "Otto Bulldog",
            "Owczarek Podhalanski",
            "Pakistani Bull Dog (Gull Dong)",
            "Pakistani Bull Terrier (Pakistani Gull Terr)",
            "Pakistani Mastiff (Pakisani Bully Kutta)",
            "Pakistani Shepherd Dog (Bhagyari Kutta)",
            "Pakistani Tazi Hound",
            "Pakistani Vikhan Dog",
            "Panda Shepherd",
            "Papillon",
            "Parson Russell Terrier",
            "Patterdale Terrier",
            "Patti",
            "Pekingese",
            "Pembroke Welsh Corgi",
            "Pencil-tail Feist",
            "Perdiguero de Burgos",
            "Perdiguero Navarro",
            "Perro Cimarron",
            "Perro de Pastor Mallorquin",
            "Perro de Presa Canario",
            "Perro de Presa Mallorquin",
            "Perro Dogo Mallorquin",
            "Perro Ratonero Andaluz",
            "Peruvian Inca Orchid (PIO)",
            "Petit Basset Griffon Vendeen",
            "Petit Bleu de Gascogne",
            "Petit Brabancon",
            "Petit Gascon Saintongeois",
            "Pharaoh Hound",
            "Phu Quoc Ridgeback Dog",
            "Picardy Spaniel",
            "Pit Bull Terrier",
            "Plott Hound",
            "Plummer Hound",
            "Pocket Beagle",
            "Podenco Ibicenco",
            "Pointer",
            "Poitevin",
            "Polish Hound",
            "Polish Tatra Sheepdog",
            "Polish Lowland Sheepdog",
            "Pomeranian",
            "Poodle",
            "Porcelaine",
            "Portuguese Hound",
            "Portuguese Pointer",
            "Portuguese Sheepdog",
            "Portuguese Water Dog",
            "Posavac Hound",
            "Potsdam Greyhound",
            "Prazsky Krysavik",
            "Presa Canario",
            "Price Boar Beisser",
            "Pudelpointer",
            "Pug",
            "Puli (Pulik)",
            "Pumi",
            "Pyrenean Mastiff",
            "Pyrenean Mountain Dog",
            "Pyrenean Shepherd",
            "Queensland Heeler",
            "Queen Elizabeth Pocket Beagle",
            "Rafeiro do Alentejo",
            "Rajapalayam",
            "Rampur Greyhound",
            "Rastreador Brasileiro",
            "Rat Terrier",
            "Redbone Coonhound",
            "Red-Tiger Bulldog",
            "Rhodesian Ridgeback",
            "Roman Rottweiler",
            "Romanian Mioritic Shepherd Dog",
            "Rottweiler",
            "Rough Collie",
            "Rumanian Sheepdog",
            "Russian Bear Schnauzer",
            "Russian Harlequin Hound",
            "Russian Hound",
            "Russian Spaniel",
            "Russian Toy",
            "Russian Tsvetnaya Bolonka",
            "Russian Wolfhound",
            "Russo-European Laika",
            "Saarlooswolfhond",
            "Sabueso Español",
            "Sage Ashayeri",
            "Sage Koochee",
            "Sage Mazandarani",
            "Saint Bernard",
            "Saluki",
            "Samoyed",
            "Sanshu Dog",
            "Sapsari",
            "Sarplaninac",
            "Schapendoes",
            "Schiller Hound",
            "Schipperke",
            "Schnauzer",
            "Scotch Collie",
            "Scottish Deerhound",
            "Scottish Terrier (Scottie)",
            "Sealydale Terrier",
            "Sealyham Terrier",
            "Segugio Italiano",
            "Serbian Hound",
            "Shar-Pei",
            "Shetland Sheepdog (Sheltie)",
            "Shiba Inu",
            "Shih Tzu",
            "Shika Inu",
            "Shikoku",
            "Shiloh Shepherd",
            "Siberian Husky",
            "Siberian Laika",
            "Silken Windhound",
            "Silky Terrier",
            "Simaku",
            "Skye Terrier",
            "Sloughi",
            "Slovakian Hound",
            "Slovakian Rough Haired Pointer",
            "Slovakian Wirehaired Pointer",
            "Slovensky Cuvac",
            "Slovensky Hrubosrsty Stavac",
            "Slovensky Kopov",
            "Smalandsstovare",
            "Small Bernese Hound",
            "Small Greek Domestic Dog",
            "Small Jura Hound",
            "Small Lucerne Hound",
            "Small Munsterlander",
            "Small Schwyz Hound",
            "Small Swiss Hound",
            "Smooth Collie",
            "Smooth Fox Terrier",
            "Soft Coated Wheaten Terrier",
            "South Russian Ovtcharka",
            "Spaniel de Pont-Audemer",
            "Spanish Bulldog",
            "Spanish Hound",
            "Spanish Mastiff",
            "Spanish Water Dog",
            "Spinone Italiano",
            "Springer Spaniel",
            "Srpski Gonic",
            "Srpski Trobojni Gonic",
            "Srpski Planinski Gonic",
            "St. Germain Pointing Dog",
            "Stabyhoun",
            "Staffordshire Bull Terrier",
            "Standard American Eskimo",
            "Standard Poodle",
            "Standard Schnauzer",
            "Stephens' Stock Mountain Cur",
            "Stichelhaar",
            "Strellufstover",
            "Styrian Roughhaired Mountain Hound",
            "Sussex Spaniel",
            "Swedish Elkhound",
            "Swedish Lapphund",
            "Swedish Vallhund",
            "Swiss Hound",
            "Swiss Laufhund",
            "Swiss Shorthaired Pinscher",
            "Tahltan Bear Dog",
            "Taigan",
            "Taiwan Dog",
            "Tamaskan Dog",
            "Tangkhul Hui",
            "Tasy",
            "Teacup Poodle",
            "Teddy Roosevelt Terrier",
            "Telomian",
            "Tenterfield Terrier",
            "Tepeizeuintli",
            "Thai Bangkaew Dog",
            "Thai Ridgeback",
            "The Carolina Dog",
            "Tibetan KyiApso",
            "Tibetan Mastiff",
            "Tibetan Spaniel",
            "Tibetan Terrier",
            "Titan Bull-Dogge",
            "Titan Terrier",
            "Tornjak",
            "Tosa Inu",
            "Toy American Eskimo",
            "Toy Fox Terrier",
            "Toy German Spitz",
            "Toy Manchester Terrier",
            "Toy Poodle",
            "Transylvanian Hound",
            "Treeing Tennessee Brindle",
            "Treeing Walker Coonhound",
            "Tuareg Sloughi",
            "Tyroler Bracke",
            "Utonagan",
            "Victorian Bulldog",
            "Villano de Las Encartaciones",
            "Vizsla",
            "Volpino Italiano",
            "Vucciriscu",
            "Weimaraner",
            "Welsh Corgi",
            "Welsh Sheepdog",
            "Welsh Springer Spaniel",
            "Welsh Terrier",
            "West Highland White Terrier (Westie)",
            "West Russian Coursing Hound",
            "West Siberian Laika",
            "Westphalian Dachsbracke",
            "Wetterhoun",
            "Wheaten Terrier",
            "Whippet",
            "White English Bulldog",
            "White German Shepherd",
            "White Swiss Shepherd",
            "Wire Fox Terrier",
            "Wirehaired Pointing Griffon",
            "Wirehaired Vizsla",
            "Xoloitzcuintle",
            "Yakutian Laika",
            "Yorkshire Terrier",
            "Yugoslavian Hound"
        ]
    }
    return breedList;
}

import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import PrivateRoute from './components/PrivateRoute';
import CustomerHome from './components/CustomerHome';
import BusinessHome from './components/BusinessHome';
import AwaitVerification from './components/AwaitVerification';
import NotFound from './components/NotFound';
import BusinessPage from './components/BusinessPage';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { db } from './config';
import { ref as dbRef, get } from 'firebase/database';
import { useEffect, useState } from 'react';

function App() {

  const [businesses, setBusinesses] = useState();
  const [callback, setCallback] = useState();

  useEffect(() => {
    get(dbRef(db, `/Businesses`)).then((snapshot) => {
      setBusinesses(Object.values(snapshot.val()));
    });
  }, []);

  return (
        <Router>
          <AuthProvider>
            <Routes>
              <Route exact path='/dashboard' element={<PrivateRoute><Dashboard/></PrivateRoute>} />
              <Route exact path='/await-verification' element={<AwaitVerification/>} />
              <Route exact path='/' element={<CustomerHome/>} />
              <Route exact path='' element={<CustomerHome/>} />
              <Route exact path='for-business' element={<BusinessHome/>} />
              <Route path='/signup' element={<Signup/>} />
              <Route path='/login' element={<Login callback={callback}/>} />
              <Route path='/forgot-password' element={<ForgotPassword/>} />

              {businesses?.map(business => {
                return (
                  <Route key={`business-page-${business.url}`} path={`/${business.url}`} element={<BusinessPage business={business} setCallback={setCallback}/>} />
                )
              })}

              <Route path='*' status={404} element={<NotFound/>}/>

            </Routes>
          </AuthProvider>
        </Router>
  );
}

export default App;
import React, { useEffect, useRef, useState } from 'react';
import { Card, Container, Row, Col, Modal, Form, Button, Badge } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../config';
import { ref as dbRef, child, get, set, query, orderByChild, equalTo } from 'firebase/database';
import { useAuth } from '../../contexts/AuthContext';
import { v4 } from 'uuid';
import durations from '../helpers/durations';

export default function CatalogueTab(props) {

  const { currentUser } = useAuth();

  const data = props.catalogue;
  const setData = props.setCatalogue;

  const [showNewServiceModal, setShowNewServiceModal] = useState(false);
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [serviceCategory, setServiceCategory] = useState();
  const [appointmentColor, setAppointmentColor] = useState('#00188f');

  const categoryNameRef = useRef();
  const serviceNameRef = useRef();
  const servicePriceRef = useRef();
  const serviceDurationRef = useRef();

  useEffect(() => {
    // get data from firebase
    const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
    get(dataRef).then(snapshot => {
      setData(Object.values(snapshot.val())[0].catalogue);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // update data in firebase
    if (data) {
      const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
      get(dataRef).then((snapshot) => {
          const dataKey = Object.keys(snapshot.val())[0];
          const targetRef = dbRef(db, `/Businesses/${dataKey}`);
          set(child(targetRef, 'catalogue'), data);
      });
    }
    //eslint-disable-next-line
  }, [data]);

  function formatPrice(price) {
    return `£${price.toFixed(2)}`
  }

  function onDragEnd(result) {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    if (type === 'category') {
      const newCategoryOrder = Array.from(data.categoryOrder);
      newCategoryOrder.splice(source.index, 1);
      newCategoryOrder.splice(destination.index, 0, draggableId);
      const newData = {
        ...data,
        categoryOrder: newCategoryOrder
      };
      setData(newData);
      return;
    }
    const start = data.categories[source.droppableId];
    const finish = data.categories[destination.droppableId];
    if (start === finish) {
      const newServiceIds = Array.from(start.serviceIds);
      newServiceIds.splice(source.index, 1);
      newServiceIds.splice(destination.index, 0, draggableId);
      const newCategory = {
        ...start,
        serviceIds: newServiceIds
      };
      const newData = {
        ...data,
        categories: {
          ...data.categories,
          [newCategory.id]: newCategory
        }
      };
      setData(newData);
      return;
    } 
    const startServiceIds = Array.from(start.serviceIds);
    startServiceIds.splice(source.index, 1);
    const newStart = {
      ...start,
      serviceIds: startServiceIds
    };
    const finishServiceIds = Array.from(finish.serviceIds || []);
    finishServiceIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      serviceIds: finishServiceIds
    };
    const newData = {
      ...data,
      categories: {
        ...data.categories,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    setData(newData);
  }

  function submitService(e) {
    e.preventDefault();
    const serviceId = v4();
    const categoryId = Object.values(data.categories).find(category => category.title === serviceCategory).id;
    let newData;
    try {
      newData = {
        ...data,
        services: {
          ...data.services,
          [serviceId]: { 
            id: serviceId, 
            name: serviceNameRef.current.value,
            duration: serviceDurationRef.current.value,
            price: Number(servicePriceRef.current.value)
          }
        },
        categories: {
          ...data.categories,
          [categoryId]: {
            ...data.categories[categoryId],
            serviceIds: [...data.categories[categoryId].serviceIds, serviceId]
          }
        }
      }
    } catch (error) {
      newData = {
        ...data,
        services: {
          ...data.services,
          [serviceId]: { 
            id: serviceId, 
            name: serviceNameRef.current.value,
            duration: serviceDurationRef.current.value,
            price: Number(servicePriceRef.current.value)
          }
        },
        categories: {
          ...data.categories,
          [categoryId]: {
            ...data.categories[categoryId],
            serviceIds: [serviceId]
          }
        }
      }
    }
    
    setData(newData);
    setShowNewServiceModal(false);
    setServiceCategory(null);
  }

  function submitCategory(e) {
    e.preventDefault();
    const categoryId = v4();
    let newData;
    try {
      newData = {
        ...data,
        categories: {
          ...data?.categories,
          [categoryId]: {
            id: categoryId,
            serviceIds: [],
            title: categoryNameRef.current.value,
            color: appointmentColor
          }
        },
        categoryOrder: [
          ...data.categoryOrder, categoryId
        ]
      }
    } catch (error) {
      newData = {
        ...data,
        categories: {
          ...data?.categories,
          [categoryId]: {
            id: categoryId,
            serviceIds: [],
            title: categoryNameRef.current.value,
            color: appointmentColor
          }
        },
        categoryOrder: [
          categoryId
        ]
      }
    }
    setData(newData);
    setShowNewCategoryModal(false);
  }

  const deleteCatPopover = (catId) => (
    <Popover id="popover-basic">
        <Popover.Body>
            <Button variant='danger' onClick={() => deleteCat(catId)}>Delete</Button>
        </Popover.Body>
    </Popover>
  );

  const deleteServicePopover = (catId, serviceId) => (
    <Popover id="popover-basic">
        <Popover.Body>
            <Button variant='danger' onClick={() => deleteService(catId, serviceId)}>Delete</Button>
        </Popover.Body>
    </Popover>
  );

  function deleteCat(catId) {
    let newServices = data.services;
    data.categories[catId].serviceIds?.forEach(c => {
      delete newServices[c];
    })

    let newCategories = data.categories;
    delete newCategories[catId];

    let newCategoryOrder = data.categoryOrder;
    const index = newCategoryOrder.indexOf(catId);
    if (index > -1) newCategoryOrder.splice(index, 1);

    const newData = {
      ...data,
      services: newServices,
      categories: newCategories,
      categoryOrder: newCategoryOrder
    }
    setData(newData);
  }

  function deleteService(catId, serviceId) {
    // delete service from data.categories[catId] serviceId
    let newServiceIds = data.categories[catId].serviceIds;
    const index = newServiceIds.indexOf(serviceId);
    if (index > -1) newServiceIds.splice(index, 1);
    const newCategories = {
      ...data.categories,
      serviceIds: newServiceIds
    };

    // delete service from data.services[serviceId]
    let newServices = data.services;
    delete newServices[serviceId];

    const newData = {
      ...data,
      services: newServices,
      categories: newCategories
    }
    setData(newData);  
  }

  function formatDuration(duration) {
    const hours = Math.floor(duration/1);
    const mins = (duration % 1)*60;
    return `${hours ? `${hours}h ` : ''}${mins ? `${mins}min` : ''}`;
}

  return (
    <>
      <Modal show={showNewServiceModal} onHide={() => {setShowNewServiceModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Add new service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
              <Form onSubmit={submitService}>
                  <Form.Group id='service-name' className='mb-3'>
                      <FloatingLabel label={'Service name'}>
                          <Form.Control type='text' ref={serviceNameRef} required />
                      </FloatingLabel>
                  </Form.Group>
                  <Dropdown className='my-4'>
                    <Dropdown.Toggle>{serviceCategory ? serviceCategory : 'Category'}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      {data ? Object.values(data.categories).map(category => {
                        return (
                          <Dropdown.Item key={category.id} onClick={() => setServiceCategory(category.title)}>{category.title} <Badge pill bg='' style={{backgroundColor: `${category.color}`}}> </Badge></Dropdown.Item>
                        )
                      }) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Group id='service-price' className='mb-3'>
                      <FloatingLabel label={'Price'}>
                          <Form.Control type='number' min="0" step=".01" ref={servicePriceRef} required />
                      </FloatingLabel>
                  </Form.Group>
                  <Form.Group id='service-duration' className='mb-3'>
                      <FloatingLabel label={'Duration'}>
                        <Form.Select required ref={serviceDurationRef} className='mb-3'>
                          {durations().map((time, i)=> {
                            return (
                              <option key={`duration-${i}`} value={time}>{time}</option>
                            )
                          })}
                        </Form.Select> 
                      </FloatingLabel>
                  </Form.Group>
                  <Button disabled={!serviceCategory} className='w-100 mt-2' type='submit'>Save</Button>
              </Form>
            </Container>
        </Modal.Body>
      </Modal>

      <Modal show={showNewCategoryModal} onHide={() => {setShowNewCategoryModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Add new category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
              <Form onSubmit={submitCategory}>
                  <Form.Label>Category name</Form.Label>
                  <Form.Control className='mb-3' type='text' ref={categoryNameRef} required />
                  <Form.Label>Appointment color</Form.Label>
                  <Dropdown className='mb-3'>
                    <Dropdown.Toggle variant='white'><FontAwesomeIcon icon={solid("circle")} size='xl'color={appointmentColor}/></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Row>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#00188f')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#00188f'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#00bcf2')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#00bcf2'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#00b294')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#00b294'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#009e49')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#009e49'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#bad80a')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#bad80a'/></Dropdown.Item></Col>
                      </Row>
                      <Row>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#fff100')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#fff100'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#ff8c00')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#ff8c00'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#e81123')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#e81123'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#ec008c')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#ec008c'/></Dropdown.Item></Col>
                        <Col><Dropdown.Item onClick={() => setAppointmentColor('#68217a')}><FontAwesomeIcon icon={solid("circle")} size='xl'color='#68217a'/></Dropdown.Item></Col>
                      </Row>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button className='w-100 mt-2' type='submit'>Save</Button>
              </Form>
            </Container>
        </Modal.Body>
      </Modal>

      <Container hidden={!props.show}>
        <Row>
          <Col xs={10}>
            <h3 className='my-4'>Services Menu</h3>
          </Col>
          <Col xs={2}>
            <Dropdown className='my-4'>
              <Dropdown.Toggle variant="dark">Add new</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => {setShowNewCategoryModal(true)}}>New category</Dropdown.Item>
                <Dropdown.Item onClick={() => {setShowNewServiceModal(true)}}>New service</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='all-categories' type='category'>
            {providedCategoryDrop => (
              <div {...providedCategoryDrop.droppableProps} ref={providedCategoryDrop.innerRef}>
                {data ? data.categoryOrder.map((catId, catIndex) => {
                  const category = data.categories[catId];
                  return (
                    <Draggable draggableId={catId} index={catIndex} key={catId}>
                      {providedCategoryDrag => (
                        <Card className='my-3' {...providedCategoryDrag.draggableProps} ref={providedCategoryDrag.innerRef}>
                          <Card.Header style={{backgroundColor: category.color+'33'}}>
                            <Row>
                              <Col xs={10}>
                                <Card.Title {...providedCategoryDrag.dragHandleProps}><FontAwesomeIcon icon={solid("grip-lines")} />&nbsp;&nbsp;&nbsp;{category.title}</Card.Title>
                              </Col>
                              <Col xs={2} style={{textAlign: 'right'}}>
                              <OverlayTrigger trigger="click" placement="bottom" overlay={deleteCatPopover(catId)} rootClose>
                                <button style={{all: 'unset', cursor: 'pointer'}}>
                                  <Card.Title><FontAwesomeIcon icon={solid("ellipsis")} /></Card.Title>
                                </button>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Header>
                          <Droppable droppableId={catId} type='service'>
                            {providedServiceDrop => (
                              <Card.Body ref={providedServiceDrop.innerRef} {...providedServiceDrop.droppableProps} style={{backgroundColor: category.color+'11'}}>
                                {category.serviceIds?.map((serviceId, index) =>{ 
                                  const service = data.services[serviceId];
                                  return (
                                    <Draggable draggableId={serviceId} index={index} key={serviceId}>
                                      {providedServiceDrag => (
                                        <Card className='my-2 p-2' {...providedServiceDrag.draggableProps} {...providedServiceDrag.dragHandleProps} ref={providedServiceDrag.innerRef}>
                                          <Row>
                                            <Col xs={6}>
                                              <Card.Text><FontAwesomeIcon icon={solid("grip-lines")} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>{service.name}</strong></Card.Text>
                                            </Col>
                                            <Col xs={3}>
                                              <Card.Text><i>{formatDuration(service.duration)}</i></Card.Text>
                                            </Col>
                                            <Col xs={2}>
                                              <Card.Text><strong>{formatPrice(service.price)}</strong></Card.Text>
                                            </Col>
                                            <Col xs={1} style={{textAlign: 'right'}}>
                                              <OverlayTrigger trigger="click" placement="bottom" overlay={deleteServicePopover(catId, serviceId)} rootClose>
                                                <button style={{all: 'unset', cursor: 'pointer'}}><FontAwesomeIcon icon={solid("ellipsis")} /></button>
                                              </OverlayTrigger>
                                              </Col>
                                          </Row>
                                        </Card>
                                      )}
                                    </Draggable>
                                  )
                                })}
                                {providedServiceDrop.placeholder}
                              </Card.Body>
                            )}
                          </Droppable>
                        </Card>
                      )}
                    </Draggable>
                  )
                }) : null}
                {providedCategoryDrop.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </>
  )
}

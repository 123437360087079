import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

export default function NotFound() {

  const [showElement,setShowElement] = useState(true)

  useEffect(() => {
    setTimeout(function() { setShowElement(false) }, 3000);
  }, []);

  return (
    <div>
        {showElement ? <Spinner/> : <><h1>Error 404</h1>Page not found</>} 
    </div>
  )
}

import React from 'react';
import { Container } from 'react-bootstrap';

export default function SettingsTab(props) {
  return (
    <>
      <Container hidden={!props.show}>
        Settings
      </Container>
    </>
  )
}

import { useAuth } from '../../contexts/AuthContext';
import { Navbar, Nav, Button, Container, OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export default function CustomerNavbar(props) {
    const navigate = useNavigate();

    const { currentUser, logout } = useAuth();

    async function handleLogout() {
        await logout();
        navigate(`${props.callback ? `/${props.callback}` : '/'}`);
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <Button className='mb-2' variant='outline-dark' onClick={() => navigate('/dashboard')}>My Bookings</Button><br/>
                <Button variant='light' onClick={handleLogout}>Logout</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <Navbar variant='dark' expand={'lg'} className="navbar-customer" collapseOnSelect>
            <Container fluid>
                <Navbar.Brand href="/"><img src='/scruff-logo.png' alt='' height='45' className='d-inline-block align-top mx-3'/></Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                <Navbar.Collapse className='my-3' id="offcanvasNavbar">
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        {currentUser ? null :
                        <Button id='offcanvas-signup-btn' className='mx-2' variant='light' href='/for-business'>For business</Button>}
                        {currentUser ? 
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                            <Button id='offcanvas-login-btn' variant='outline-light'>{currentUser.email}</Button>
                        </OverlayTrigger> 
                        : <Button id='offcanvas-login-btn' variant='outline-light' href='/login'>Login</Button>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Form, Button, Container, Modal, Row, Col, InputGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { db } from '../../config';
import { ref as dbRef, get, set, query, orderByChild, equalTo, child } from 'firebase/database';
import { useAuth } from '../../contexts/AuthContext';
import durations from '../helpers/durations';
import { nanoid } from 'nanoid';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../custom.css';

export default function CalendarTab(props) {
    const appointments = props.appointments;
    const setAppointments = props.setAppointments;

    const { currentUser } = useAuth();

    moment.updateLocale('gb', { week: { dow: 1, doy: 1 } });
    const localizer = momentLocalizer(moment);

    const [showNewEventModal, setShowNewEventModal] = useState(false);
    const [showEditEventModal, setShowEditEventModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isBlockTime, setIsBlockTime] = useState(false);
    const [start, setStart] = useState('');
    const [openingHours, setOpeningHours] = useState();
    const [selectedService, setSelectedService] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedClient, setSelectedClient] = useState();
    const [selectedPets, setSelectedPets] = useState();
    const [duration, setDuration] = useState('');
    const [status, setStatus] = useState();
    const [clientPets, setClientPets] = useState([]);
    const [editNotes, setEditNotes] = useState();
    const [editPrice, setEditPrice] = useState();
    const [editDuration, setEditDuration] = useState();
    const [editStart, setEditStart] = useState();
    const [editingAppointment, setEditingAppointment] = useState();
    const [editEventId, setEditEventId] = useState();
    const [editEventIndex, setEditEventIndex] = useState();

    const startRef = useRef();
    const priceRef = useRef();
    const notesRef = useRef();

    const onSelectSlot = (slotInfo) => {
        setStart(moment(slotInfo.start).toISOString(true).split('+')[0]);
        setShowNewEventModal(true);
    }

    const onSelectEvent = (eventInfo) => {
        setEditingAppointment(true);
        setIsBlockTime(eventInfo.isBlock);
        setDuration(eventInfo.duration);
        let startTime = eventInfo.start;
        startTime.setMinutes(startTime.getMinutes() - startTime.getTimezoneOffset());
        setEditStart(startTime);
        setSelectedCategory(eventInfo.category);
        setSelectedService(eventInfo.service);
        setSelectedClient(eventInfo.client);
        setEditNotes(eventInfo.notes);
        setEditPrice(eventInfo.price);
        setEditDuration(eventInfo.duration);
        setStatus(eventInfo.status);
        setClientPets(eventInfo.pets);
        setShowEditEventModal(true);
        setEditEventId(eventInfo.id);
        setEditEventIndex(appointments.map(e => e.id).indexOf(eventInfo.id));
    }

    const closeEditModal = () => {
        setIsBlockTime(null);
        setDuration('');
        setStatus(null);
        setStart(null);
        setSelectedCategory(null);
        setSelectedService(null);
        setSelectedClient(null);
        setEditNotes(null);
        setEditPrice(null);
        setEditDuration(null);
        setClientPets([]);
        setShowEditEventModal(false);
        setEditingAppointment(false);
    }

    function deleteEvent() {
        const newData = [...appointments];
        newData.splice(editEventIndex, 1);
        setAppointments(newData);
        setShowDeleteModal(false);
        setShowEditEventModal(false);
        setIsBlockTime(null);
        setDuration('');
        setStatus(null);
        setStart(null);
        setSelectedCategory(null);
        setSelectedService(null);
        setSelectedClient(null);
        setEditNotes(null);
        setEditPrice(null);
        setClientPets([]);
        setShowEditEventModal(false);
        setEditingAppointment(false);
    }

    function editEvent(e) {
        e.preventDefault();

        const start = new Date(moment(startRef.current.value));
        const end = new Date(moment(startRef.current.value).add(formatDuration(duration), 'hours'));
        let newAppointment;
        if (isBlockTime) {
            newAppointment = {
                id: editEventId,
                start: start.toISOString(true),
                end: end.toISOString(true), 
                duration: duration,
                notes: notesRef.current.value,
                isBlock: isBlockTime,
                title: 'Blocked time'
            }
        } else {
            const petsStr = selectedPets.map(pet => { return `${pet.name} ${pet.breed1}${pet.breed2 ? ` X ${pet.breed2}` : ''} ${pet.age} yrs`});
            const description = `${selectedCategory.title}\n${selectedClient.firstName} ${selectedClient.lastName}\n${petsStr}`;
            newAppointment = {
                id: editEventId,
                start: start.toISOString(true),
                end: end.toISOString(true),
                duration: duration,
                notes: notesRef.current.value,
                isBlock: isBlockTime,
                title: selectedService.name,
                desc: description,
                price: priceRef.current.value,
                client: selectedClient,
                service: selectedService,
                category: selectedCategory,
                pets: clientPets,
                status: status
            }
        }

        const newData = [...appointments];
        newData.splice(editEventIndex, 1, newAppointment);
        setAppointments(newData);

        setEditingAppointment(false);
        setSelectedService(null);
        setSelectedCategory(null);
        setSelectedClient(null);
        setIsBlockTime(false);
        setShowEditEventModal(false);
        setClientPets([]);
    }

    function EventTooltip({ event }) {
        return (
          <span>
            <strong>{event.title}</strong>
            {event.desc && ':  ' + event.desc}
          </span>
        )
    }

    function formatDuration(duration) {
        let hours = 0;
        const dArr = duration.split(' ');
        dArr.forEach(i => {
            if (i.endsWith('h')) hours += Number(i.substring(0, i.length - 1));
            if (i.endsWith('min')) hours += (Number(i.substring(0, i.length - 3))/60);
        });
        return hours;
    }

    function durationToString(duration) {
        const hours = Math.floor(duration/1);
        const mins = (duration % 1)*60;
        return `${hours ? `${hours}h ` : ''}${mins ? `${mins}min` : ''}`;
    }

    function submitEvent(e) {
        e.preventDefault();
        const start = new Date(moment(startRef.current.value));
        const end = new Date(moment(startRef.current.value).add(formatDuration(duration), 'hours'));
        const eventId = nanoid(11);
        let newAppointment;
        if (isBlockTime) {
            newAppointment = {
                id: eventId,
                start: start.toISOString(true),
                end: end.toISOString(true), 
                duration: duration,
                notes: notesRef.current.value,
                isBlock: isBlockTime,
                title: isBlockTime ? 'Blocked time' : selectedService.name,
            }
        } else {
            const petsStr = selectedPets.map(pet => { return `${pet.name} ${pet.breed1}${pet.breed2 ? ` X ${pet.breed2}` : ''} ${pet.age} yrs`});
            const description = `${selectedCategory.title}\n${selectedClient.firstName} ${selectedClient.lastName}\n${petsStr}`;
            newAppointment = {
                id: eventId,
                start: start.toISOString(true),
                end: end.toISOString(true), 
                duration: duration,
                notes: notesRef.current.value,
                isBlock: isBlockTime,
                title: isBlockTime ? 'Blocked time' : selectedService.name,
                desc: description,
                price: priceRef.current.value,
                client: selectedClient,
                service: selectedService,
                category: selectedCategory,
                pets: clientPets,
                status: 'confirmed'
            }
        }
        
        if (appointments) {
            setAppointments([...appointments, newAppointment]);
        } else {
            setAppointments([newAppointment]);
        }
        setSelectedService(null);
        setSelectedCategory(null);
        setSelectedClient(null);
        setIsBlockTime(false);
        setShowNewEventModal(false);
        setClientPets([]);
    }

    const get5minIncrements = () => {
        var x = 5; 
        var times = []; 
        var tt = 0;

        for (var i=0;tt<24*60; i++) {
            var hh = Math.floor(tt/60); 
            var mm = (tt%60); 
            times[i] = hh + ':' + ("0" + mm).slice(-2);
            tt = tt + x;
        }
        return times;
    }

    useEffect(() => {
        if (openingHours){
            const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
            get(dataRef).then((snapshot) => {
                const dataKey = Object.keys(snapshot.val())[0];
                const targetRef = dbRef(db, `/Businesses/${dataKey}`);
                set(child(targetRef, 'openingHours'), openingHours);
            });
        }
        //eslint-disable-next-line
    }, [openingHours])

    useMemo(() => {
        // get opening hours from firebase
        const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
        get(dataRef).then((snapshot) => {
            setOpeningHours(Object.values(snapshot.val())[0].openingHours);
            setAppointments(Object.values(snapshot.val())[0].appointments);
        });

        //eslint-disable-next-line
    }, []);

    const slotPropGetter = useCallback(
        (date) => (
            {
                ...((((moment(date).day() === 0 && (moment(date).hour() < openingHours?.sun.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.sun.start?.split(':')[0])) && moment(date).minutes() < openingHours?.sun.start?.split(':')[1])))
                        || (moment(date).day() === 0 && (moment(date).hour() > openingHours?.sun.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.sun.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.sun.end?.split(':')[1]))))
                                || (moment(date).day() === 0 && openingHours?.sun.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
                ...((((moment(date).day() === 1 && (moment(date).hour() < openingHours?.mon.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.mon.start?.split(':')[0])) && moment(date).minutes() < openingHours?.mon.start?.split(':')[1])))
                        || (moment(date).day() === 1 && (moment(date).hour() > openingHours?.mon.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.mon.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.mon.end?.split(':')[1]))))
                                || (moment(date).day() === 1 && openingHours?.mon.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
                ...((((moment(date).day() === 2 && (moment(date).hour() < openingHours?.tue.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.tue.start?.split(':')[0])) && moment(date).minutes() < openingHours?.tue.start?.split(':')[1])))
                        || (moment(date).day() === 2 && (moment(date).hour() > openingHours?.tue.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.tue.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.tue.end?.split(':')[1]))))
                                || (moment(date).day() === 2 && openingHours?.tue.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
                ...((((moment(date).day() === 3 && (moment(date).hour() < openingHours?.wed.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.wed.start?.split(':')[0])) && moment(date).minutes() < openingHours?.wed.start?.split(':')[1])))
                        || (moment(date).day() === 3 && (moment(date).hour() > openingHours?.wed.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.wed.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.wed.end?.split(':')[1]))))
                                || (moment(date).day() === 3 && openingHours?.wed.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
                ...((((moment(date).day() === 4 && (moment(date).hour() < openingHours?.thu.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.thu.start?.split(':')[0])) && moment(date).minutes() < openingHours?.thu.start?.split(':')[1])))
                        || (moment(date).day() === 4 && (moment(date).hour() > openingHours?.thu.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.thu.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.thu.end?.split(':')[1]))))
                                || (moment(date).day() === 4 && openingHours?.thu.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
                ...((((moment(date).day() === 5 && (moment(date).hour() < openingHours?.fri.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.fri.start?.split(':')[0])) && moment(date).minutes() < openingHours?.fri.start?.split(':')[1])))
                        || (moment(date).day() === 5 && (moment(date).hour() > openingHours?.fri.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.fri.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.fri.end?.split(':')[1]))))
                                || (moment(date).day() === 5 && openingHours?.fri.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
                ...((((moment(date).day() === 6 && (moment(date).hour() < openingHours?.sat.start?.split(':')[0] 
                    || (moment(date).hour() === (Number(openingHours?.sat.start?.split(':')[0])) && moment(date).minutes() < openingHours?.sat.start?.split(':')[1])))
                        || (moment(date).day() === 6 && (moment(date).hour() > openingHours?.sat.end?.split(':')[0] 
                            || (moment(date).hour() === (Number(openingHours?.sat.end?.split(':')[0])) && moment(date).minutes() >= openingHours?.sat.end?.split(':')[1]))))
                                || (moment(date).day() === 6 && openingHours?.sat.closed))
                    && {
                    style: {
                        background: "repeating-linear-gradient(45deg,#EEEEEE,#EEEEEE 10px,white 10px,white 20px)",
                        backgroundColor: '#ffffff00'
                    }
                }),
            }
        ), [openingHours]
    );

    function pickService(e, service, category) {
        e.preventDefault();
        
        setSelectedService(service);
        setSelectedCategory(category);
        setDuration(durationToString(service.duration));
    }

    function pickClient(e, client) {
        e.preventDefault();
        if (!selectedClient || selectedClient.email !== client.email) {
            setSelectedClient(client);
            setClientPets(client.pets);
        }
    }

    useEffect(() => {
        if (selectedClient && !editingAppointment) setClientPets(selectedClient.pets);
    }, [selectedClient, editingAppointment]);

    useEffect(() => {
        if (appointments){
            const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
            get(dataRef).then((snapshot) => {
                const dataKey = Object.keys(snapshot.val())[0];
                const targetRef = dbRef(db, `/Businesses/${dataKey}`);
                set(child(targetRef, 'appointments'), appointments);
            });
        }
    // eslint-disable-next-line 
    }, [appointments]);

    useEffect(() => {
        if (isBlockTime) {
            setSelectedService(null);
            setSelectedCategory(null);
            setSelectedClient(null);
            setClientPets([]);
        }
    }, [isBlockTime]);

    useEffect(() => {
        if (clientPets) setSelectedPets(clientPets.filter(p => p.selected))
    }, [clientPets]);

    function selectPet(pet, i) {
        const newClientPet = {...pet, selected: !clientPets[i].selected};
        const newClientPets = [...clientPets];
        newClientPets[i] = newClientPet

        setClientPets(newClientPets);
    }

    return (
        <>
            <Modal show={showEditEventModal} onHide={closeEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit appointment</Modal.Title>
                    <Form.Check type="checkbox" className='mx-3' label="Block time" checked={isBlockTime} onChange={() => setIsBlockTime(!isBlockTime)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={editEvent}>
                        <div hidden={isBlockTime}>
                        <h5>{selectedCategory?.title}</h5>
                        <Row>
                            <Col>
                                <DropdownButton className='my-3' title={selectedService?.name || 'Select service'} disabled={isBlockTime} variant='dark'>
                                    {props.catalogue?.categories ? Object.values(props.catalogue.categories).map(category => {
                                        return (
                                            <>
                                                <Dropdown.ItemText key={`dropdown-${category.id}-1`}><strong>{category.title}</strong></Dropdown.ItemText>
                                                {category.serviceIds?.map(serviceId => {
                                                    return (
                                                        <Dropdown.Item as="button" key={`dropdown-${serviceId}-1`} onClick={e => pickService(e, props.catalogue.services[serviceId], category)}>{props.catalogue.services[serviceId].name} - <i>{durationToString(props.catalogue.services[serviceId].duration)}</i></Dropdown.Item>
                                                    )
                                                })}
                                            </>
                                        )
                                    }) : null}
                                </DropdownButton>
                            </Col>
                            <Col className='mx-5'>
                                <InputGroup className='my-3'>
                                    <InputGroup.Text>£</InputGroup.Text>
                                    <Form.Control type='number' defaultValue={editPrice} step=".01" ref={priceRef}/>
                                </InputGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>Client</h5>
                        <DropdownButton className='my-3' title={selectedClient ?`${selectedClient?.firstName} ${selectedClient?.lastName}` : 'Select client'} disabled={isBlockTime} variant={'dark'}>
                            {props.clients ? props.clients.map(client => {
                                return (
                                    <Dropdown.Item as="button" key={`dropdown-${client.email}`} onClick={e => pickClient(e, client)}>{client.firstName} {client.lastName}</Dropdown.Item>
                                )
                            }) : null}
                        </DropdownButton>
                        <Row className='my-3' hidden={clientPets?.length < 1}>
                            <Col xs={1}></Col>
                            <Col xs={3}><strong>Name</strong></Col>
                            <Col xs={3}><strong>Breed 1</strong></Col>
                            <Col xs={3}><strong>Breed 2</strong></Col>
                            <Col xs={2}><strong>Age</strong></Col>
                        </Row>
                        {clientPets?.map((pet, i) => {
                            return (
                                <Row className='my-1' key={`${pet.name}-${i}`}>
                                    <Col xs={1}><Form.Check checked={clientPets[i].selected} onChange={() => selectPet(pet, i)}/></Col>
                                    <Col xs={3}>{pet.name}</Col>
                                    <Col xs={3}>{pet.breed1}</Col>
                                    <Col xs={3}>{pet.breed2 || '-'}</Col>
                                    <Col xs={2}>{pet.age} yr</Col>
                                </Row>
                            )
                        })}
                        <hr/>
                        </div>
                        <h5>Time</h5>
                        <Row>
                            <Col className='mt-1'>
                                <Form.Label><strong>Start</strong></Form.Label>
                                <Form.Control type='datetime-local' ref={startRef} defaultValue={editStart ? editStart.toISOString().slice(0, -1) : null}/>
                            </Col>
                            <Col className='mx-3 mt-1'>
                                <Form.Label><strong>Duration</strong></Form.Label>
                                <Form.Select required disabled={!selectedService && !isBlockTime} defaultValue={editDuration}
                                    onChange={(e) => setDuration(e.currentTarget.value)}>
                                    {durations().map((time, i)=> {
                                        return (
                                            <option key={`duration-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                            </Form.Select>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>Notes</h5>
                        <Form.Control as="textarea" className='mb-3' ref={notesRef} defaultValue={editNotes}/>
                        {!isBlockTime ? <>Status: <strong>{status}</strong></> : null}
                        <div style={{textAlign: 'right'}}>
                            <Button className='w-10 mt-2 mx-3' variant='danger' disabled={showDeleteModal} onClick={() => setShowDeleteModal(true)}>Delete</Button>
                            <Button className='w-10 mt-2' type='submit' disabled={(!(selectedService && selectedPets?.length) && !isBlockTime) || showDeleteModal} variant='success'>Save</Button>
                        </div>
                        <Alert show={showDeleteModal} onClose={() => setShowDeleteModal(false)} dismissible variant='danger' className='my-2'>
                            Are you sure? this action cannot be undone. <Alert.Link className='w-10' variant='danger' onClick={() => deleteEvent()}>Delete</Alert.Link>
                        </Alert>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showNewEventModal} onHide={() => {setShowNewEventModal(false); setSelectedService(null); setIsBlockTime(false); setSelectedClient(null); setSelectedCategory(null); setClientPets([]);}}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new appointment</Modal.Title>
                    <Form.Check type="checkbox" className='mx-3' label="Block time" checked={isBlockTime} onChange={() => setIsBlockTime(!isBlockTime)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitEvent}>
                        <div hidden={isBlockTime}>
                        <h5>{selectedCategory?.title}</h5>
                        <Row>
                            <Col>
                                <DropdownButton className='my-3' title={selectedService?.name || 'Select service'} disabled={isBlockTime} variant='dark'>
                                    {props.catalogue?.categories ? Object.values(props.catalogue.categories).map(category => {
                                        return (
                                            <>
                                                <Dropdown.ItemText key={`dropdown-${category.id}-2`}><strong>{category.title}</strong></Dropdown.ItemText>
                                                {category.serviceIds?.map(serviceId => {
                                                    return (
                                                        <Dropdown.Item as="button" key={`dropdown-${serviceId}-2`} onClick={e => pickService(e, props.catalogue.services[serviceId], category)}>{props.catalogue.services[serviceId].name} - <i>{durationToString(props.catalogue.services[serviceId].duration)}</i></Dropdown.Item>
                                                    )
                                                })}
                                            </>
                                        )
                                    }) : null}
                                </DropdownButton>
                            </Col>
                            <Col className='mx-5'>
                                <div key={selectedService?.price}>
                                    <InputGroup className='my-3'>
                                        <InputGroup.Text>£</InputGroup.Text>
                                        <Form.Control type='number' defaultValue={selectedService?.price.toFixed(2)} step=".01" ref={priceRef}/>
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <h5>Client</h5>
                        <DropdownButton className='my-3' title={selectedClient ?`${selectedClient?.firstName} ${selectedClient?.lastName}` : 'Select client'} disabled={isBlockTime} variant='dark'>
                            {props.clients ? props.clients.map(client => {
                                return (
                                    <Dropdown.Item as="button" key={`dropdown-${client.email}`} onClick={e => pickClient(e, client)}>{client.firstName} {client.lastName}</Dropdown.Item>
                                )
                            }) : null}
                        </DropdownButton>
                        <Row className='my-3' hidden={clientPets?.length < 1}>
                            <Col xs={1}></Col>
                            <Col xs={3}><strong>Name</strong></Col>
                            <Col xs={3}><strong>Breed 1</strong></Col>
                            <Col xs={3}><strong>Breed 2</strong></Col>
                            <Col xs={2}><strong>Age</strong></Col>
                        </Row>
                        {clientPets?.map((pet, i) => {
                            return (
                                <Row className='my-1' key={`${pet.name}-${i}`}>
                                    <Col xs={1}><Form.Check checked={clientPets[i].selected} onChange={() => selectPet(pet, i)}/></Col>
                                    <Col xs={3}>{pet.name}</Col>
                                    <Col xs={3}>{pet.breed1}</Col>
                                    <Col xs={3}>{pet.breed2 || '-'}</Col>
                                    <Col xs={2}>{pet.age} yr</Col>
                                </Row>
                            )
                        })}
                        <hr/>
                        </div>
                        <h5>Time</h5>
                        <div className='d-flex'>
                            <FloatingLabel label={'Start'} className='my-3'>
                                <Form.Control type='datetime-local' ref={startRef} defaultValue={start}/>
                            </FloatingLabel>
                            <div key={selectedService?.duration}>
                                <FloatingLabel label={'Duration'} className='mx-3 my-3'>
                                    <Form.Select required disabled={!selectedService && !isBlockTime} defaultValue={durationToString(selectedService?.duration)} 
                                        onChange={(e) => setDuration(e.currentTarget.value)}>
                                        {durations().map((time, i)=> {
                                            return (
                                                <option key={`duration-${i}`} value={time}>{time}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                        </div>
                        <hr/>
                        <h5>Notes</h5>
                        <Form.Control as="textarea" className='mb-3' ref={notesRef}/>
                        <div style={{textAlign: 'right'}}>
                            <Button className='w-10 mt-2' type='submit' disabled={!(selectedService && selectedPets?.length) && !isBlockTime} variant='success'>Save</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showSettingsModal} onHide={() => {setShowSettingsModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Opening hours</h5>
                    <Form className='mt-4'>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.mon.closed} onChange={() => setOpeningHours({...openingHours, mon: {...openingHours.mon, closed: !openingHours.mon.closed}})}/></Col>
                            <Col xs={3}>Monday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.mon.closed} defaultValue={openingHours?.mon.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, mon: {...openingHours.mon, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`mon-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.mon.closed} defaultValue={openingHours?.mon.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, mon: {...openingHours.mon, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`mon-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.tue.closed} onChange={() => setOpeningHours({...openingHours, tue: {...openingHours.tue, closed: !openingHours.tue.closed}})}/></Col>
                            <Col xs={3}>Tuesday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.tue.closed} defaultValue={openingHours?.tue.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, tue: {...openingHours.tue, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`tue-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.tue.closed} defaultValue={openingHours?.tue.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, tue: {...openingHours.tue, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`tue-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.wed.closed} onChange={() => setOpeningHours({...openingHours, wed: {...openingHours.wed, closed: !openingHours.wed.closed}})}/></Col>
                            <Col xs={3}>Wednesday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.wed.closed} defaultValue={openingHours?.wed.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, wed: {...openingHours.wed, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`wed-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.wed.closed} defaultValue={openingHours?.wed.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, wed: {...openingHours.wed, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`wed-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.thu.closed} onChange={() => setOpeningHours({...openingHours, thu: {...openingHours.thu, closed: !openingHours.thu.closed}})}/></Col>
                            <Col xs={3}>Thursday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.thu.closed} defaultValue={openingHours?.thu.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, thu: {...openingHours.thu, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`thu-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.thu.closed} defaultValue={openingHours?.thu.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, thu: {...openingHours.thu, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`thu-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.fri.closed} onChange={() => setOpeningHours({...openingHours, fri: {...openingHours.fri, closed: !openingHours.fri.closed}})}/></Col>
                            <Col xs={3}>Friday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.fri.closed} defaultValue={openingHours?.fri.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, fri: {...openingHours.fri, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`fri-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.fri.closed} defaultValue={openingHours?.fri.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, fri: {...openingHours.fri, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`fri-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.sat.closed} onChange={() => setOpeningHours({...openingHours, sat: {...openingHours.sat, closed: !openingHours.sat.closed}})}/></Col>
                            <Col xs={3}>Saturday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.sat.closed} defaultValue={openingHours?.sat.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, sat: {...openingHours.sat, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`sat-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.sat.closed} defaultValue={openingHours?.sat.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, sat: {...openingHours.sat, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`sat-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col xs={1}><Form.Check checked={!openingHours?.sun.closed} onChange={() => setOpeningHours({...openingHours, sun: {...openingHours.sun, closed: !openingHours.sun.closed}})}/></Col>
                            <Col xs={3}>Sunday</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.sun.closed} defaultValue={openingHours?.sun.start} 
                                    onChange={(e) => setOpeningHours({...openingHours, sun: {...openingHours.sun, start: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`sun-from-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                            <Col xs={1}>_</Col>
                            <Col>
                                <Form.Select required disabled={openingHours?.sun.closed} defaultValue={openingHours?.sun.end} 
                                    onChange={(e) => setOpeningHours({...openingHours, sun: {...openingHours.sun, end: e.currentTarget.value}})}>
                                    {get5minIncrements().map((time, i)=> {
                                        return (
                                            <option key={`sun-to-${i}`} value={time}>{time}</option>
                                        )
                                    })}
                                </Form.Select> 
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

            <Container className='mt-4' hidden={!props.show}>
                <Row>
                    <Col xs={10}></Col>
                    <Col xs={2} style={{textAlign: 'right'}}>
                        <Button className='mb-2' variant='dark' size='sm' onClick={() => setShowSettingsModal(true)}><FontAwesomeIcon icon={solid("cog")} /></Button>
                    </Col>
                </Row>

                <Calendar
                    localizer={localizer}
                    events={appointments?.map(app => {
                        return {...app, start: moment(app.start).toDate(), end: moment(app.end).toDate()}})
                    }
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '80vh'}}
                    selectable
                    onSelectSlot={onSelectSlot}
                    onSelecting = {slot => false}
                    onSelectEvent={onSelectEvent}
                    components={{
                        event: EventTooltip
                    }}
                    scrollToTime={new Date()}
                    slotPropGetter={slotPropGetter}
                    eventPropGetter={
                        (event) => {
                            let newStyle = {};
                            newStyle.zIndex = 2
                            if (event.isBlock) {
                                newStyle.background = "grey";
                            } else if (event.status==='pending') {
                                 newStyle.background = "#eeeeee";
                                 newStyle.color = 'black';
                            } else {
                                newStyle.background = event.category.color
                                newStyle.color = event.category.color==='#fff100' ? 'black' : 'white';
                            }
                            return {
                                className: "",
                                style: newStyle
                            };
                        }
                    }
                    step={15}       
                    timeslots={4}
                    views={['month', 'week', 'day']}
                    defaultView='week'
                />
            </Container>
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Modal, Form, Button, InputGroup, Table, Card, Alert } from 'react-bootstrap';
import { db } from '../../config';
import { ref as dbRef, child, get, set, query, orderByChild, equalTo } from 'firebase/database';
import { useAuth } from '../../contexts/AuthContext';
import CountryCodes from '../helpers/CountryCodes';
import breeds from '../helpers/breeds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import '../../custom.css';
import SelectSearch from 'react-select-search';
import '../../search.css';

export default function ClientsTab(props) {
  const data = props.clients;
  const setData = props.setClients;

  const { currentUser } = useAuth();

  const [showNewClientModal, setShowNewClientModal] = useState();
  const [showEditClientModal, setShowEditClientModal] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [textNotifications, setTextNotifications] = useState(true);
  const [pets, setPets] = useState([]);
  const [newPetName, setNewPetName] = useState();
  const [newPetBreed1, setNewPetBreed1] = useState(null);
  const [newPetBreed2, setNewPetBreed2] = useState(null);
  const [newPetAge, setNewPetAge] = useState();
  const [editClientIndex, setEditClientIndex] = useState();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const countryCodeRef = useRef();
  const mobileNumberRef = useRef();
  const emailRef = useRef();

  useEffect(() => {
    // get data from firebase
    const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
    get(dataRef).then(snapshot => {
      setData(Object.values(snapshot.val())[0].clients);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // update data in firebase
    if (data) {
      const dataRef = query(dbRef(db, `/Businesses`), orderByChild('email'), equalTo(currentUser.email));
      get(dataRef).then((snapshot) => {
          const dataKey = Object.keys(snapshot.val())[0];
          const targetRef = dbRef(db, `/Businesses/${dataKey}`);
          set(child(targetRef, 'clients'), data);
      });
    }
    //eslint-disable-next-line
  }, [data]);

  function submitClient(e) {
    e.preventDefault();
    const client = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      mobileNumber: formatMobileNumber(),
      email: emailRef.current.value,
      pets: pets,
      created: (new Date()).toLocaleString()
    }
    if (data) {
      setData(prev => [...prev, client]);
    } else {
      setData([client]);
    }
    setShowNewClientModal(false);
    setPets([]);
  }

  function formatMobileNumber() {
    return `+${countryCodeRef.current.value}${mobileNumberRef.current.value.slice(-10)}`
  }

  function addPet(e) {
    e.preventDefault();
    setPets(prev => [...prev, {
      name: newPetName || '',
      breed1: newPetBreed1 || '',
      breed2: newPetBreed2 || '',
      age: newPetAge || 0
    }]);
    setNewPetName('');
    setNewPetBreed1('');
    setNewPetBreed2('');
    setNewPetAge('');
  }

  function deletePet(i) {
    const newPets = [...pets];
    newPets.splice(i, 1);
    setPets(newPets);
  }

  function editClient(e) {
    e.preventDefault();
    const newClient = {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      mobileNumber: formatMobileNumber(),
      email: emailRef.current.value,
      pets: pets,
      lastUpdated: (new Date()).toLocaleString(),
      created: data[editClientIndex].created
    }
    const newData = [...data];
    newData.splice(editClientIndex, 1, newClient);
    setData(newData);
    setShowEditClientModal(false);
    setPets([]);
  }

  function deleteClient() {
    const newData = [...data];
    newData.splice(editClientIndex, 1);
    setData(newData);
    setShowDeleteModal(false);
    setShowEditClientModal(false);
    setPets([]);
  }

  const options = breeds('dog').map(function(breed) {
    return {name: breed, value: breed}
  });

  return (
    <>
      <Modal show={showEditClientModal} onHide={() => {setShowEditClientModal(false); setPets([])}} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Edit client</Modal.Title>
        </Modal.Header>
        <Form onSubmit={editClient}>
          <Modal.Body>
            <h5>Basic Info</h5>
            <Row className='my-3'>
              <Col xs={3}>
                <Form.Label>First name</Form.Label>
                <Form.Control type='text' ref={firstNameRef} defaultValue={data ? data[editClientIndex]?.firstName : ''}/>
              </Col>
              <Col xs={3}>
                <Form.Label>Last name</Form.Label>
                <Form.Control type='text' ref={lastNameRef} defaultValue={data ? data[editClientIndex]?.lastName : ''}/>
              </Col>
              <Col xs={6}>
                <Form.Label>Mobile number</Form.Label>
                <InputGroup>
                  <div style={{width:'25%'}}>
                    <CountryCodes ref={countryCodeRef} />
                  </div>
                  <Form.Control type='tel' ref={mobileNumberRef} defaultValue={data ? data[editClientIndex]?.mobileNumber.slice(-10) : ''}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Label>Email address</Form.Label>
                <Form.Control type='email' ref={emailRef} defaultValue={data ? data[editClientIndex]?.email : ''}/>
              </Col>
            </Row>
            <hr/>
            <h5>Notifications</h5>
            <Row className='my-3'>
              <Col>
                <Form.Check type="switch" id="custom-switch" label="Send email notifications" checked={emailNotifications} onChange={() => setEmailNotifications(!emailNotifications)}/>
              </Col>
              <Col>
                <Form.Check type="switch" id="custom-switch" label="Send text (SMS) notifications" checked={textNotifications} onChange={() => setTextNotifications(!textNotifications)}/>
              </Col>
            </Row>
            <hr/>
            <Row className='my-3'>
              <Col xs={11}><h5>Pets</h5></Col>
            </Row>
            <Row className='my-3'><Col><strong>Name</strong></Col><Col><strong>Breed 1</strong></Col><Col><strong>Breed 2</strong></Col><Col><strong>Age in years</strong></Col><Col></Col></Row>
            {pets?.map((pet, i) => {
              return (
                <Row className='my-1' key={`${pet.name}-${i}`}>
                  <Col>{pet.name}</Col>
                  <Col>{pet.breed1}</Col>
                  <Col>{pet.breed2}</Col>
                  <Col>{pet.age}</Col>
                  <Col><Button onClick={() => deletePet(i)} size='sm' variant='danger'><FontAwesomeIcon icon={solid("minus")} /></Button></Col>
                </Row>
              )
            })}
            <Row>
              <Col><Form.Control type='text' value={newPetName} onChange={(e) => setNewPetName(e.target.value)} placeholder='name'/></Col>
              <Col>
                <SelectSearch options={options} name="breeds" value={newPetBreed1} placeholder="Search breed 1" search onChange={setNewPetBreed1}/>
              </Col>
              <Col>
                <SelectSearch options={options} name="breeds" value={newPetBreed2} placeholder="Search breed 2" search onChange={setNewPetBreed2}/>
              </Col>
              <Col><Form.Control type='number' value={newPetAge} onChange={(e) => setNewPetAge(e.target.value)} placeholder='age'/></Col>
              <Col><Button onClick={e => addPet(e)} size='sm' variant='dark'><FontAwesomeIcon icon={solid("plus")} /></Button></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className='w-10 mt-3' variant='danger' disabled={showDeleteModal} onClick={() => setShowDeleteModal(true)}>Delete</Button>
            <Button className='w-10 mt-3' type='submit' variant='success' disabled={showDeleteModal}>Save</Button>
          </Modal.Footer>
          <Alert show={showDeleteModal} onClose={() => setShowDeleteModal(false)} dismissible variant='danger' className='my-2 mx-2'>
            Are you sure? this action cannot be undone. <Alert.Link className='w-10' variant='danger' onClick={() => deleteClient()}>Delete</Alert.Link>
          </Alert>
        </Form>
      </Modal>

      <Modal show={showNewClientModal} onHide={() => {setShowNewClientModal(false); setPets([])}} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add new client</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitClient}>
          <Modal.Body>
            <h5>Basic Info</h5>
            <Row className='my-3'>
              <Col xs={3}>
                <Form.Label>First name</Form.Label>
                <Form.Control type='text' ref={firstNameRef} required />
              </Col>
              <Col xs={3}>
                <Form.Label>Last name</Form.Label>
                <Form.Control type='text' ref={lastNameRef} required />
              </Col>
              <Col xs={6}>
                <Form.Label>Mobile number</Form.Label>
                <InputGroup>
                  <div style={{width:'25%'}}>
                    <CountryCodes ref={countryCodeRef} />
                  </div>
                  <Form.Control type='tel' ref={mobileNumberRef} required />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Label>Email address</Form.Label>
                <Form.Control type='email' ref={emailRef} required />
              </Col>
            </Row>
            <hr/>
            <h5>Notifications</h5>
            <Row className='my-3'>
              <Col>
                <Form.Check type="switch" id="custom-switch" label="Send email notifications" checked={emailNotifications} onChange={() => setEmailNotifications(!emailNotifications)}/>
              </Col>
              <Col>
                <Form.Check type="switch" id="custom-switch" label="Send text (SMS) notifications" checked={textNotifications} onChange={() => setTextNotifications(!textNotifications)}/>
              </Col>
            </Row>
            <hr/>
            <Row className='my-3'>
              <Col xs={11}><h5>Pets</h5></Col>
            </Row>
            {pets?.map((pet, i) => {
              return (
                <Row className='my-1' key={`${pet.name}-${i}`}>
                  <Col>{pet.name}</Col>
                  <Col>{pet.breed1}</Col>
                  <Col>{pet.breed2}</Col>
                  <Col>{pet.age}</Col>
                  <Col><Button onClick={() => deletePet(i)} size='sm' variant='danger'><FontAwesomeIcon icon={solid("minus")} /></Button></Col>
                </Row>
              )
            })}
            <Row>
              <Col><Form.Control type='text' value={newPetName} onChange={(e) => setNewPetName(e.target.value)} placeholder='name'/></Col>
              <Col>
                <SelectSearch options={options} name="breeds" value={newPetBreed1} placeholder="Search breed 1" search onChange={setNewPetBreed1}/>
              </Col>
              <Col>
                <SelectSearch options={options} name="breeds" value={newPetBreed2} placeholder="Search breed 2" search onChange={setNewPetBreed2}/>
              </Col>
              <Col><Form.Control type='number' value={newPetAge} onChange={(e) => setNewPetAge(e.target.value)} placeholder='age'/></Col>
              <Col><Button onClick={e => addPet(e)} size='sm' variant='dark'><FontAwesomeIcon icon={solid("plus")} /></Button></Col>
            </Row>
            
          </Modal.Body>
          <Modal.Footer>
            <Button className='w-10 mt-3' type='submit' variant='success'>Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Container hidden={!props.show}>
      <Row className='my-4'>
          <Col xs={10}>
            <h3>Clients List</h3>
          </Col>
          <Col xs={2}>
                <Button variant='dark' onClick={() => {setShowNewClientModal(true)}}>Add Client</Button>
          </Col>
        </Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Client name</th>
              <th>Mobile number</th>
              <th>Created</th>
              <th>Pets</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((client, i) => {
              return (
                <tr key={client.email} className='client-row' onClick={() => {setEditClientIndex(i); setShowEditClientModal(true); setPets(client.pets)}}>
                  <td><strong>{client.firstName} {client.lastName}</strong><br/><i>{client.email}</i></td>
                  <td>{client.mobileNumber}</td>
                  <td>{client.created}</td>
                  <td>{client.pets?.map((pet, i) => {
                    return (
                      <Card key={`client-pets-${pet.name}-${i}`} className='my-1 p-2'>
                        <Row>
                          <Col><strong>{pet.name}</strong></Col>
                          <Col>{pet.breed1} {pet.breed2 ? `X ${pet.breed2}` : null}</Col>
                          <Col>{pet.age} yr</Col>
                        </Row>
                      </Card>
                    )
                  })}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Container>
    </>
  )
}

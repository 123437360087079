import React, { useEffect, useState } from 'react';
import CustomerNavbar from './navbars/CustomerNavbar';
import { Container, Table } from 'react-bootstrap';
import { db } from '../config';
import { ref as dbRef, child, get, set, query, orderByChild, equalTo } from 'firebase/database';
import { useAuth } from '../contexts/AuthContext';
import moment from 'moment';

export default function CustomerDashboard() {

  const [appointments, setAppointments] = useState();

  const { currentUser } = useAuth();

  useEffect(() => {
    // get data from firebase
    const dataRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(currentUser.email));
    get(dataRef).then(snapshot => {
      setAppointments(Object.values(snapshot.val())[0].appointments);
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // update data in firebase
    if (appointments) {
      const dataRef = query(dbRef(db, `/Customers`), orderByChild('email'), equalTo(currentUser.email));
      get(dataRef).then((snapshot) => {
        const dataKey = Object.keys(snapshot.val())[0];
        const targetRef = dbRef(db, `/Customers/${dataKey}`);
        set(child(targetRef, 'appointments'), appointments);
      });
    }
    //eslint-disable-next-line
  }, [appointments]);

  const AppointmentsTable = (props) => {
    let newAppointments = [];
    appointments?.forEach(appointment => {
      if (((props.type==='upcoming' && moment(appointment.start).isAfter(moment()) && appointment.status!=='cancelled')
        || (props.type==='recent' && moment(appointment.start).isBefore(moment()) && appointment.status!=='cancelled')
          || (props.type==='in-progress' && moment(appointment.start).isBefore(moment()) && moment(appointment.end).isAfter(moment()) && appointment.status!=='cancelled')
            || (props.type==='cancelled' && appointment.status==='cancelled'))
              && !appointment.isBlock)
              newAppointments.push(appointment);
    })
    if (newAppointments.length < 1) return ( <p>None</p> )
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>business</th>
            <th>status</th>
            <th>pet</th>
            <th>category</th>
            <th>service</th>
            <th>price</th>
            <th>start</th>
            <th>duration</th>
          </tr>
        </thead>
        <tbody>
          {newAppointments.length > 0 ? newAppointments.map(appointment => {
            return (
              <tr key={`${props.type}-${appointment.id}`}>
                <td><b>{appointment.business.name}</b><br/>{appointment.business.address}<br/>{appointment.business.email}</td>
                <td>{appointment.status}</td>
                <td>{appointment.pets[0].name}</td>
                <td>{appointment.category.title}</td>
                <td>{appointment.service.name}</td>
                <td>£{appointment.price}</td>
                <td>{moment(appointment.start).toDate().toLocaleString('en-GB', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                <td>{appointment.duration}</td>
              </tr>
            )
          }) : null}
        </tbody>
      </Table>
    )
  }

  return (
    <>
      <CustomerNavbar/>
      <Container>
        <div className='my-3'>
          <h1>Upcoming</h1>
          <AppointmentsTable type='upcoming'/>
        </div>
        
        <div className='my-3'>
          <h1>In progress</h1>
          <AppointmentsTable type='in-progress'/>
        </div>

        <div className='my-3'>
          <h1>Previous</h1>
          <AppointmentsTable type='recent'/>
        </div>

        <div className='my-3'>
          <h1>Cancelled</h1>
          <AppointmentsTable type='cancelled'/>
        </div>
      </Container>
    </>
  )
}

// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from "firebase/functions";

const app = initializeApp({
    apiKey: "AIzaSyBFM-7W8X4CM-JvHKaodj_87YyJ4ZPMD1U",
    authDomain: "scruffbook.firebaseapp.com",
    databaseURL: 'https://scruffbook-default-rtdb.europe-west1.firebasedatabase.app/',
    projectId: "scruffbook",
    storageBucket: "scruffbook.appspot.com",
    messagingSenderId: "450523103990",
    appId: "1:450523103990:web:714e8bf55e1270e34122d7",
    measurementId: "G-WY0JW2JZW4"
});

// const analytics = getAnalytics(app);
export const storage = getStorage(app);

export const db = getDatabase(app);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const functions = getFunctions(app, 'europe-west1');
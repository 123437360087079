import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import HomeTab from './business-dashboard-components/HomeTab';
import CalendarTab from './business-dashboard-components/CalendarTab';
import ClientTab from './business-dashboard-components/ClientsTab';
import CatalogueTab from './business-dashboard-components/CatalogueTab';
import ProfileTab from './business-dashboard-components/ProfileTab';
import SettingsTab from './business-dashboard-components/SettingsTab';
import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';

export default function BusinessDashboard() {
    const [homeView, setHomeView] = useState(false);
    const [calendarView, setCalendarView] = useState(true);
    const [clientsView, setClientsView] = useState(false);
    const [catalogueView, setCatalogueView] = useState(false);
    const [profileView, setProfileView] = useState(false);
    const [settingsView, setSettingsView] = useState(false);
    const [catalogue, setCatalogue] = useState();
    const [clients, setClients] = useState();
    const [appointments, setAppointments] = useState();

    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    async function handleLogout() {
        await logout();
        navigate('/for-business');
    }

    function handleHomeView() {
        if (!homeView) {
            setHomeView(true);
            setCalendarView(false); 
            setClientsView(false); 
            setCatalogueView(false);
            setProfileView(false);
            setSettingsView(false);
        }
    }
    function handleCalendarView() {
        if (!calendarView) {
            setHomeView(false);
            setCalendarView(true); 
            setClientsView(false); 
            setCatalogueView(false);
            setProfileView(false);
            setSettingsView(false);
        }
    }
    function handleClientsView() {
        if (!clientsView) {
            setHomeView(false);
            setCalendarView(false); 
            setClientsView(true); 
            setCatalogueView(false);
            setProfileView(false);
            setSettingsView(false);
        }
    }
    function handleCatalogueView() {
        if (!catalogueView) {
            setHomeView(false);
            setCalendarView(false); 
            setClientsView(false); 
            setCatalogueView(true);
            setProfileView(false);
            setSettingsView(false);
        }
    }
    function handleProfileView() {
        if (!profileView) {
            setHomeView(false);
            setCalendarView(false); 
            setClientsView(false); 
            setCatalogueView(false);
            setProfileView(true);
            setSettingsView(false);
        }
    }
    function handleSettingsView() {
        if (!settingsView) {
            setHomeView(false);
            setCalendarView(false); 
            setClientsView(false); 
            setCatalogueView(false);
            setProfileView(false);
            setSettingsView(true);
        }
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <Button variant='light' onClick={handleLogout}>Logout</Button>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <Navbar variant='dark' expand={'lg'} className="navbar-business navbar-business-dash" collapseOnSelect>
                <Container fluid>
                    <Navbar.Brand href="#"><img src='/scruff-logo2.png' alt='' height='45' className='d-inline-block align-top mx-3'/></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                    <Navbar.Collapse className='my-3' id="offcanvasNavbar">
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                                <Button id='offcanvas-login-btn' variant='outline-light'>{currentUser.email}</Button>
                            </OverlayTrigger> 
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Row>
                <Col xs={1} style={{ height: '100vh', textAlign: 'center', borderRight: '2px solid #102E44', color: '#102E44'}}>      
                <Nav className="col-md-12 d-none d-md-block mt-4">
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement={'right'} overlay={<Tooltip>Home</Tooltip>}>
                        <Button size='lg' className="mb-4" variant='white' onClick={handleHomeView} disabled={homeView}><FontAwesomeIcon icon={solid("home")} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement={'right'} overlay={<Tooltip>Calendar</Tooltip>}>
                        <Button size='lg' className="mb-4"  variant='white' onClick={handleCalendarView} disabled={calendarView}><FontAwesomeIcon icon={solid("calendar-days")} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement={'right'} overlay={<Tooltip>Clients</Tooltip>}>
                        <Button size='lg' className="mb-4" variant='white' onClick={handleClientsView} disabled={clientsView}><FontAwesomeIcon icon={solid("user-group")} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement={'right'} overlay={<Tooltip>Catalogue</Tooltip>}>
                        <Button size='lg' className="mb-4" variant='white' onClick={handleCatalogueView} disabled={catalogueView}><FontAwesomeIcon icon={solid("book-open")} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement={'right'} overlay={<Tooltip>Profile</Tooltip>}>
                        <Button size='lg' className="mb-4" variant='white' onClick={handleProfileView} disabled={profileView}><FontAwesomeIcon icon={solid("user")} /></Button>
                    </OverlayTrigger>
                    <OverlayTrigger trigger={['hover', 'focus', 'click']} placement={'right'} overlay={<Tooltip>Settings</Tooltip>}>
                        <Button size='lg' className="mb-4" variant='white' onClick={handleSettingsView} disabled={settingsView}><FontAwesomeIcon icon={solid("cog")} /></Button>
                    </OverlayTrigger>
                </Nav>
                </Col>
                <Col xs={11}>
                    <HomeTab appointments={appointments} setAppointments={setAppointments} show={homeView}/>
                    <CalendarTab  appointments={appointments} setAppointments={setAppointments} catalogue={catalogue} clients={clients} show={calendarView}/>
                    <ClientTab show={clientsView} clients={clients} setClients={setClients}/>
                    <CatalogueTab catalogue={catalogue} setCatalogue={setCatalogue} show={catalogueView}/>
                    <ProfileTab show={profileView}/>
                    <SettingsTab show={settingsView}/>
                </Col> 
            </Row>
        </>
    )
}

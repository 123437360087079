import React, { useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container } from 'react-bootstrap';
import { sendEmailVerification } from 'firebase/auth';

export default function AwaitVerification() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(function() {
            currentUser.reload();
            if (currentUser.emailVerified) {
                navigate('/dashboard');
            }
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [])

    return (
        <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}>
            <div className='w-100' style={{ maxWidth: '400px' }}>
                <>
                    <Card>
                        <Card.Body>
                            <h2 className='text-center mb-4'>Awaiting Email Verification</h2>
                            <p className='text-center'>Click the button below to resend verification email</p>
                            <Button className='w-100 mt-2' type='submit' onClick={() => {sendEmailVerification(currentUser)}}>Resend</Button>
                        </Card.Body>
                    </Card>
                </>
            </div>
        </Container>
    )
}
